<template>
	<DaModalFrame v-model:open="modalIsOpen">
		<LoadingDowloadPDF />
	</DaModalFrame>
	<div class="p-6 md-down:px-4">
		<div
			class="
				__breadcrumbs
				text-sm text-neutral-500
				font-semibold
				flex-nowrap flex
				items-center
			"
		>
			<router-link :to="`/plans/${student_id}/history-plan`">
				Lịch sử kế hoạch
			</router-link>
			<div class="__breadcrumbs__decorate">
				<DaIcon
					name="das--chevron-down"
					class="transform -rotate-90 h-3 w-3 mx-2"
				/>
			</div>
			<div>Xem kế hoạch can thiệp đã dạy</div>
		</div>
		<div class="__goal-management-student-detail__header pt-5">
			<div class="flex justify-between items-center w-full">
				<router-link :to="`/record/student/${student?.id}`">
					<div class="flex items-center">
						<img
							style="min-width: 56px"
							class="
								shadow-sm
								inline-block
								h-14
								w-14
								rounded-full
							"
							:src="student?.profile_photo?.w200"
							v-if="student?.profile_photo?.w200"
						/>
						<span
							style="min-width: 56px"
							v-else
							class="
								inline-flex
								items-center
								justify-center
								h-14
								w-14
								rounded-full
							"
							:style="{
								background:
									student?.profile_photo?.default?.color,
							}"
						>
							<span
								class="
									font-medium
									leading-none
									text-white text-4xl
								"
								>{{
									student?.profile_photo?.default?.initial
								}}</span
							>
						</span>
						<div class="ml-4">
							<div
								class="
									font-bold
									text-neutral-700 text-4xl
									md-down:text-2xl
								"
							>
								{{ student?.name }}
							</div>
							<div
								v-if="student?.age"
								class="
									text-green-500
									font-semibold
									text-xs
									uppercase
								"
							>
								{{ student?.age }}
								{{
									student?.learning_time
										? `| ${student?.learning_time}`
										: ''
								}}
							</div>
						</div>
					</div>
				</router-link>
				<div
					v-if="data?.month_start"
					class="
						flex
						md-down:w-full
						justify-start
						md-down:flex-row-reverse
					"
				>
					<router-link
						:to="`/report/monthly-report/${student_id}?month_start=${data?.month_start}`"
					>
						<button type="button" class="button-p btn--ghost">
							Xem Báo cáo kế hoạch
						</button>
					</router-link>
				</div>
			</div>
		</div>
	</div>
	<div class="p-6 mt-6">
		<div class="p-6 bg-white shadow-sm rounded-2xl mb-10">
			<div>
				<div class="flex items-center justify-between mb-8">
					<div class="font-semibold text-neutral-600">
						{{ data?.name || 'Kế hoạch' }}
					</div>
					<div class="flex items-center">
						<div
							class="font-semibold text-neutral-600 mr-8"
							v-if="data?.applied_at"
						>
							Áp dụng:
							{{ $filters.formatDate(data?.applied_at) }}
						</div>
						<Menu as="div" class="ml-3 relative">
							<div>
								<MenuButton
									class="
										rounded-full
										flex
										text-sm
										focus:outline-none
										focus:ring-2
										focus:ring-offset-2
										focus:ring-indigo-500
									"
								>
									<span class="sr-only">Open user menu</span>
									<svg
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
											fill="#94A3B8"
										/>
									</svg>
								</MenuButton>
							</div>
							<transition
								enter-active-class="transition ease-out duration-200"
								enter-from-class="transform opacity-0 scale-95"
								enter-to-class="transform opacity-100 scale-100"
								leave-active-class="transition ease-in duration-75"
								leave-from-class="transform opacity-100 scale-100"
								leave-to-class="transform opacity-0 scale-95"
							>
								<MenuItems
									class="
										origin-top-right
										absolute
										right-0
										mt-2
										w-60
										rounded-md
										py-1
										bg-white
										focus:outline-none
										z-10
										shadow-sm
										border border-yellow-100
									"
								>
									<MenuItem
										v-if="goals && goals.length > 0"
										@click="downloadPDF()"
										v-slot="{ active }"
									>
										<div
											class="
												p-2
												px-4
												bg-white
												z-10
												text-gray-600
												hover:bg-neutral-50
												flex
												items-center
												gap-4
												cursor-pointer
											"
											:class="active ? '' : ''"
										>
											<svg
												width="16"
												height="16"
												viewBox="0 0 16 16"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M8 0C6.57359 0 5.31176 0.560456 4.40576 1.34182C3.63985 2.00237 3.08479 2.8617 2.94223 3.725C1.26586 4.09512 0 5.55511 0 7.31818C0 9.36599 1.70754 11 3.78125 11H7.5V5.5C7.5 5.22386 7.72386 5 8 5C8.27614 5 8.5 5.22386 8.5 5.5V11H12.6875C14.5023 11 16 9.56949 16 7.77273C16 6.13656 14.7581 4.80411 13.1657 4.57898C12.9233 1.99944 10.6902 0 8 0Z"
													fill="#94A3B8"
												/>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M7.64645 15.8536C7.84171 16.0488 8.15829 16.0488 8.35355 15.8536L11.3536 12.8536C11.5488 12.6583 11.5488 12.3417 11.3536 12.1464C11.1583 11.9512 10.8417 11.9512 10.6464 12.1464L8.5 14.2929V11H7.5V14.2929L5.35355 12.1464C5.15829 11.9512 4.84171 11.9512 4.64645 12.1464C4.45118 12.3417 4.45118 12.6583 4.64645 12.8536L7.64645 15.8536Z"
													fill="#94A3B8"
												/>
											</svg>

											<span class="mt-0.5"
												>Tải file .PDF</span
											>
										</div>
									</MenuItem>
								</MenuItems>
							</transition>
						</Menu>
					</div>
				</div>
			</div>
			<div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6">
				<div
					class="
						p-6
						border
						lg:rounded-l-lg
						md:rounded-tl-lg
						border-neutral-200
						w-full
						md-down:rounded-t md-down:rounded-b-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/abc.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ skills_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Tổng số lĩnh vực
						</span>
					</div>
				</div>
				<div
					class="
						p-6
						border border-neutral-200
						w-full
						md:border-l-0
						md-down:border-t-0
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/light.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ goals_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Tổng số mục tiêu</span
						>
					</div>
				</div>
				<div
					class="
						p-6
						border border-neutral-200
						w-full
						md:rounded-tr-lg
						lg:rounded-tr-none
						md:border-l-0
						md-down:border-t-0
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/light.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ continued_goals_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Mục tiêu lặp lại</span
						>
					</div>
				</div>
				<div
					class="
						p-6
						border border-neutral-200
						w-full
						lg:border-l-0
						lg-down:border-t-0
						md:rounded-bl-lg
						lg:rounded-bl-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/light.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ new_goals_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Mục tiêu mới</span
						>
					</div>
				</div>
				<div
					class="
						p-6
						border border-neutral-200
						w-full
						md:border-l-0
						lg-down:border-t-0
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/light.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ goals_from_stopped_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Từ MT tạm dừng</span
						>
					</div>
				</div>
				<div
					class="
						p-6
						border
						md:border-l-0
						lg:rounded-r-lg
						md:rounded-br-lg
						border-neutral-200
						w-full
						lg-down:border-t-0
						md-down:rounded-b md-down:rounded-t-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/light.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ goals_from_lib_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Từ Ngân hàng MT</span
						>
					</div>
				</div>
			</div>
			<div class="mt-4 flex justify-end items-center text-gray-700">
				<div
					v-if="data && data.sent_at && data.sent_by_staff"
					class="flex gap-2"
				>
					<router-link
						:to="`/record/employee/${data.sent_by_staff?.id}`"
					>
						<div class="flex items-center">
							<div>
								<img
									class="
										shadow-sm
										inline-block
										h-6
										w-6
										rounded-full
									"
									:src="
										data.sent_by_staff?.profile_photo?.w200
									"
									alt=""
									v-if="
										data.sent_by_staff?.profile_photo?.w200
									"
								/>
								<span
									v-else
									class="
										inline-flex
										items-center
										justify-center
										h-6
										w-6
										rounded-full
									"
									:style="{
										background:
											data.sent_by_staff?.profile_photo
												?.default?.color,
									}"
								>
									<span
										class="
											font-medium
											leading-none
											text-white text-xs
										"
										>{{
											data.sent_by_staff?.profile_photo
												?.default?.initial
										}}</span
									>
								</span>
							</div>
							<div class="ml-4 text-blue-600 font-semibold">
								{{ data.sent_by_staff.name }}
							</div>
						</div>
					</router-link>
					đã gửi KH tới PH
					<span>{{ $filters.formatDateTime(data.sent_at) }}</span>
				</div>
				<div v-else></div>
			</div>
		</div>
		<div v-if="goals && goals.length > 0">
			<div>
				<div class="mb-10" v-for="(goal, index) in goals" :key="index">
					<div class="font-semibold text-lg text-neutral-600 mb-4">
						{{ index + 1 }}. {{ goal.skill.name }} ({{
							goal.goals.length
						}})
					</div>
					<div class="shadow-sm sm:rounded-2xl overflow-hidden">
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									overflow-hidden
									sm:rounded-t-2xl
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-16
													whitespace-nowrap
												"
											>
												STT
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
												style="min-width: 160px"
											>
												Nội dung
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(g, i) in goal.goals"
											:key="g.id"
											:class="
												i % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
											class="text-neutral-700"
										>
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div class="ml-2">
													{{ i + 1 }}
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
												"
											>
												<div class="flex items-center">
													<router-link
														:to="`/goal/${id}/${
															g.goal_id || g.id
														}`"
													>
														<div
															class="
																mr-2
																hover:text-yellow-700
																hover:underline
															"
														>
															{{ g.goal_name }}
														</div>
													</router-link>
													<TooltipGoalDetail
														:goal-id="
															g.goal_id || g.id
														"
														:student-id="id"
													/>
												</div>
												<div
													class="flex gap-2 flex-wrap"
													style="max-width: 360px"
												>
													<div
														class="
															mt-1
															py-0.5
															px-2.5
															rounded-full
															bg-neutral-200
															text-neutral-700
															h-fit
															w-fit
															whitespace-nowrap
															font-semibold
															shadow-input
														"
														style="font-size: 10px"
														v-if="g?.raw_source"
													>
														{{ g?.display_source }}
													</div>
													<template
														v-if="
															g?.assistance_levels &&
															org.enable_goal_assistance_level
														"
													>
														<div
															v-for="ass in g?.assistance_levels"
															:key="ass"
															class="flex gap-2"
														>
															<div
																class="
																	mt-1
																	py-0.5
																	px-2.5
																	rounded-full
																	bg-pink-100
																	whitespace-nowrap
																	text-neutral-700
																	h-fit
																	w-fit
																	font-semibold
																	shadow-input
																"
																style="
																	font-size: 10px;
																"
															>
																{{ ass }}
															</div>
														</div>
													</template>
												</div>
												<div
													v-if="
														g.parent
															?.sub_goal_count > 0
													"
													class="
														goal-block
														flex
														gap-3
														mt-2
													"
												>
													<img
														class="w-3 h-3 mt-1"
														src="@/assets/images/icons/goal-arrow.png"
														alt=""
													/>
													<div>
														<div
															class="
																text-neutral-700
															"
														>
															{{ g.parent?.name }}
														</div>
														<div
															class="
																text-neutral-400
																text-xs
																font-semibold
															"
														>
															{{
																g.parent
																	?.sub_goal_count
															}}
															MỤC TIÊU
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useStudent } from '@/features/student/useStudent';
import { useRoute } from 'vue-router';
import DaIcon from '@/components/elements/daIcon/DaIcon';
import { $api } from '@/services';
import { useStore } from 'vuex';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import LoadingDowloadPDF from '@/views/plansDetail/features/reportTarget/loadingDowloadPDF/loadingDowloadPDF.vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';

const route = useRoute();
const student = ref(null);
const store = useStore();
const data = ref(null);
const goals = ref([]);
const modalIsOpen = ref(false);
const goals_count = ref(0);
const skills_count = ref(0);
const continued_goals_count = ref(0);
const goals_from_lib_count = ref(0);
const goals_from_stopped_count = ref(0);
const new_goals_count = ref(0);

const { getStudent } = useStudent();

const org = computed(() => store.state.profile.current_org);
const id = computed(() => String(route.params.id || ''));
const student_id = computed(() => route.params.student_id);

onMounted(() => {
	planActive();
	fetchStudent();
});

async function fetchStudent() {
	const res = await getStudent(String(student_id.value || ''));
	if (res?.data) {
		student.value = res?.data;
	}
}

async function planActive() {
	try {
		const {
			data: { data: data_report, stats, skills },
		} = await $api.monthlyReport.planDetail(
			String(student_id.value || ''),
			String(id.value || '')
		);
		data.value = data_report;
		if (skills) {
			goals.value = skills.continued;
		}
		if (stats) {
			goals_count.value = stats.goals_count;
			skills_count.value = stats.skills_count;
			continued_goals_count.value = stats.continued_goals_count;
			goals_from_lib_count.value = stats.goals_from_lib_count;
			goals_from_stopped_count.value = stats.goals_from_stopped_count;
			new_goals_count.value = stats.new_goals_count;
		}
	} catch (e) {
		console.log(e);
	}
}
async function downloadPDF() {
	try {
		modalIsOpen.value = true;
		const url =
			process.env.VUE_APP_PDF_URL +
			'plan-in-history/' +
			student_id.value +
			'/' +
			id.value +
			'?token=' +
			localStorage.getItem('tk') +
			'&subdomain=' +
			localStorage.getItem('sub-domain');
		const params = {
			url: url,
			format: 'a2',
			id_render: 'plans_pdf',
		};
		const response = await fetch(
			'https://g4ridpplxi.execute-api.ap-southeast-1.amazonaws.com/',
			{
				method: 'POST',
				body: JSON.stringify(params),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			}
		);
		const data = await response.json();
		if (data.data) {
			cancel();
			const linkSource = `data:application/pdf;base64, ${data.data}`;
			const link = document.createElement('a');
			const fileName = 'KeHoachHienTai.pdf';
			link.href = linkSource;
			link.download = fileName;
			link.click();
		}
	} catch (e) {
		cancel();
		await store.dispatch('setAlertMessage', {
			message:
				e?.response?.data?.errors?.join('<br/>') ||
				'Đã có lỗi xảy ra. Vui lòng thử lại',
			type: 'danger',
		});
		console.log(e);
	}
	function cancel() {
		modalIsOpen.value = false;
	}
}
</script>
<style scoped>
.translate {
	transform: translate(-50%, -50%);
}
</style>
