<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-2/5">
		<div
			class="
				h-full
				w-full
				flex flex-col
				bg-white
				shadow-xl
				overflow-y-auto
			"
		>
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							fill="none"
							height="24"
							viewBox="0 0 24 24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12.75 6.75C12.75 6.33579 12.4142 6 12 6C11.5858 6 11.25 6.33579 11.25 6.75V11.25H6.75C6.33579 11.25 6 11.5858 6 12C6 12.4142 6.33579 12.75 6.75 12.75H11.25V17.25C11.25 17.6642 11.5858 18 12 18C12.4142 18 12.75 17.6642 12.75 17.25V12.75H17.25C17.6642 12.75 18 12.4142 18 12C18 11.5858 17.6642 11.25 17.25 11.25H12.75V6.75Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
						</svg>

						<div
							class="
								text-2xl
								font-bold
								text-neutral-700
								ml-2
								mt-1
							"
						>
							<div>Tạo thông báo mới</div>
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							type="button"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								aria-hidden="true"
								class="h-6 w-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6 18L18 6M6 6l12 12"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="sideover-content mt-6 relative flex-1 px-4 sm:px-6 pb-20"
			>
				<div>
					<div class="input-group__label mb-2">Biểu mẫu</div>
					<div class="bg-white select-tabs">
						<Multiselect
							v-model="form"
							:allow-empty="false"
							:options="forms"
							:searchable="true"
							deselect-label="Đã chọn"
							label="nameSearch"
							placeholder="Chọn biểu mẫu"
							selectLabel=""
							track-by="name"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									<div>
										{{ props.option.name }}
									</div>
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									<div>{{ option.name }}</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div class="mt-8">
					<div class="input-group__label mb-2">Học sinh</div>
					<div class="bg-white select-tabs">
						<Multiselect
							v-model="student"
							:allow-empty="false"
							:options="
								students?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							deselect-label="Đã chọn"
							label="nameSearch"
							placeholder="Chọn học sinh"
							selectLabel=""
							track-by="id"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									<div>
										{{ props.option.name }}
									</div>
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									<div>{{ option.name }}</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div class="mt-8">
					<div class="input-group">
						<div class="input-group__label mb-2">
							Tháng thu học phí
						</div>
						<VueDatepicker
							v-model="month"
							:max-date="new Date()"
							:min-date="new Date(2023, 1, 0)"
							auto-apply
							class="input-date w-full"
							format="MM/yyyy"
							month-picker
							placeholder="Chọn tháng"
							uid="filter-by-date"
						>
							<template #input-icon>
								<svg
									fill="none"
									height="16"
									viewBox="0 0 16 16"
									width="16"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										clip-rule="evenodd"
										d="M4 0.5C4 0.223858 3.77614 0 3.5 0C3.22386 0 3 0.223858 3 0.5V1H2C0.895431 1 0 1.89543 0 3V4H16V3C16 1.89543 15.1046 1 14 1H13V0.5C13 0.223858 12.7761 0 12.5 0C12.2239 0 12 0.223858 12 0.5V1H4V0.5ZM16 14V5H0V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14ZM9.5 7C9.22386 7 9 7.22386 9 7.5V8.5C9 8.77614 9.22386 9 9.5 9H10.5C10.7761 9 11 8.77614 11 8.5V7.5C11 7.22386 10.7761 7 10.5 7H9.5ZM12.5 7C12.2239 7 12 7.22386 12 7.5V8.5C12 8.77614 12.2239 9 12.5 9H13.5C13.7761 9 14 8.77614 14 8.5V7.5C14 7.22386 13.7761 7 13.5 7H12.5ZM2 10.5C2 10.2239 2.22386 10 2.5 10H3.5C3.77614 10 4 10.2239 4 10.5V11.5C4 11.7761 3.77614 12 3.5 12H2.5C2.22386 12 2 11.7761 2 11.5V10.5ZM5.5 10C5.22386 10 5 10.2239 5 10.5V11.5C5 11.7761 5.22386 12 5.5 12H6.5C6.77614 12 7 11.7761 7 11.5V10.5C7 10.2239 6.77614 10 6.5 10H5.5Z"
										fill="#94A3B8"
										fill-rule="evenodd"
									/>
								</svg>
							</template>
						</VueDatepicker>
					</div>
				</div>
			</div>
			<div
				class="
					px-6
					py-4
					border-t border-yellow-50
					flex
					justify-end
					gap-6
				"
			>
				<div class="flex justify-end">
					<button
						:disabled="!form || !student || !month"
						class="button-p text-center w-32"
						type="button"
						@click="submit"
					>
						Xác nhận
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import Multiselect from '@suadelabs/vue3-multiselect';
import { $api } from '@/services';
import { _ROUTER_NAME } from '@/enums';
import { toEnglish } from '@/helper/toEnglish';

export default defineComponent({
	name: 'CreateNotify',
	components: { Multiselect },

	props: {
		open: {
			type: Boolean,
			required: true,
		},
		formSelected: {
			type: Object,
		},
	},

	watch: {
		open: {
			immediate: true,
			handler(val) {
				if (val) {
					this.getFormsByMonth();
					this.getStudents();
					this.form = this.formSelected;
				}
			},
		},
	},

	data() {
		return {
			form: null,
			student: null,
			month: null,
			students: [],
			forms: [],
		};
	},

	computed: {
		org() {
			return this.$store.state.profile.current_org;
		},

		isAdmin() {
			return this.$store.state.profile.current_org?.role === 'admin';
		},
	},

	methods: {
		toEnglish,
		async getFormsByMonth() {
			try {
				this.isLoading = true;
				const { data } = await $api.org.getForms();
				this.forms = data?.data || [];
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.isLoading = false;
			}
		},

		async getStudents() {
			try {
				const { data } = await $api.student.getStudents(
					!this.isAdmin ? 'staff' : 'org_admin'
				);
				this.students = data?.data ?? [];
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.isLoading = false;
			}
		},

		submit() {
			window.sessionStorage.setItem(
				'formNotify',
				JSON.stringify({
					form: this.form,
					student: this.student,
					month: this.month,
				})
			);
			let monthStart = `${this.month.year}-${
				this.month.month <= 8 ? '0' : ''
			}${this.month.month + 1}-01`;
			this.$router.push(
				`/fee-notices/${this.form.id}/create?id=${this.student.id}&month_start=${monthStart}`
			);
		},
	},
});
</script>
