<template>
	<div
		style="max-width: 600px"
		class="
			fixed
			inset-y-0
			right-0
			flex
			side-over
			z-50
			w-screen
			md:w-2/5
			overflow-y-auto
		"
	>
		<div
			class="h-full w-full flex flex-col bg-white shadow-xl"
			style="min-height: 100vh; height: fit-content"
		>
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M13.6308 5.68773L15.8838 3.4347C16.7496 2.56892 17.1825 2.13603 17.6895 1.99525C18.0396 1.89805 18.4095 1.89805 18.7596 1.99525C19.2667 2.13603 19.6996 2.56892 20.5653 3.4347C21.4311 4.30048 21.864 4.73337 22.0048 5.24043C22.102 5.59051 22.102 5.96045 22.0048 6.31052C21.864 6.81758 21.4311 7.25047 20.5653 8.11625L18.3384 10.3432C16.3859 9.22338 14.7651 7.61487 13.6308 5.68773ZM12.1764 7.1421L3.8659 15.4526C3.44084 15.8777 3.22831 16.0902 3.08857 16.3513C2.94884 16.6124 2.88989 16.9071 2.772 17.4966L1.98622 21.4255C1.91969 21.7581 1.88643 21.9244 1.98104 22.019C2.07564 22.1136 2.24194 22.0804 2.57452 22.0138L2.57455 22.0138L2.57456 22.0138L6.50349 21.228L6.50351 21.228C7.09295 21.1102 7.38767 21.0512 7.64876 20.9115C7.90986 20.7717 8.12239 20.5592 8.54743 20.1342L8.54745 20.1342L16.8803 11.8013C14.9786 10.6172 13.3722 9.0219 12.1764 7.1421Z"
								fill="#94A3B8"
							/>
						</svg>
						<div class="text-4xl font-bold text-neutral-700 ml-1.5">
							Chỉnh sửa hoạt động
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div class="my-6 relative flex-1 px-4 sm:px-6">
				<div class="whitespace-nowrap text-base text-neutral-700">
					Lớp bán trú
					<span class="font-bold">{{ props.boarding?.name }}</span>
				</div>
				<div class="flex justify-end mt-2">
					<button
						type="button"
						class="
							h-12
							button-p
							btn--ghost
							text-center
							px-6
							whitespace-nowrap
							mt-4
							w-max
							gap-2
						"
						@click="createInput"
						:disabled="disable"
						:class="{ 'disable-button': disable }"
					>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
								:fill="disable ? '#CBD5E1' : '#E5A42B'"
							/>
						</svg>
						Thêm hoạt động
					</button>
				</div>
				<div class="mt-3" id="list_activity">
					<div v-for="(i, index) in activities" :key="index">
						<div
							class="
								whitespace-nowrap
								text-base text-neutral-700
								mb-1
								font-semibold
							"
						>
							Hoạt động
							{{ activities.length - index }}
						</div>
						<textarea
							v-model="i.name"
							placeholder="Tên hoạt động"
							:class="errorActivities[index] ? 'text-error' : ''"
						></textarea>
						<div
							class="text-sienna-600 text-sm"
							v-if="errorActivities[index]"
						>
							{{ errorActivities[index] }}
						</div>
						<div
							class="
								flex
								gap-1
								items-center
								justify-end
								mt-1
								cursor-pointer
							"
							v-if="i.id"
							@click="deleteInput(i.id)"
						>
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
									fill="#E5A42B"
								/>
							</svg>
							<div class="text-base text-yellow-600">XOÁ</div>
						</div>
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							type="button"
							class="button-p text-center w-max"
							@click="addActivities"
						>
							Lưu chỉnh sửa
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue';
import { $api } from '@/services';
import { useStore } from 'vuex';

const props = defineProps(['boarding', 'open']);
const emit = defineEmits(['submit']);
const store = useStore();
const activities = ref([]);
const disable = ref(false);
const errorActivities = ref(['']);

watch(
	() => props.open,
	(val) => {
		if (val == true) getActivities();
	}
);
const createInput = () => {
	if (disable.value == true) return;
	const length = activities.value.length;
	if (length > 0 && activities.value[0].name?.length <= 0) {
		console.log(activities.value[0].name);
		errorActivities.value[0] = 'Tên hoạt động không được trống';
		disable.value = true;
		setTimeout(() => {
			errorActivities.value[0] = '';
			disable.value = false;
		}, 2000);
	} else {
		activities.value.unshift({
			name: '',
		});
		errorActivities.value.unshift('');
	}
};
const deleteInput = (id) => {
	console.log(id);
	activities.value = activities.value.filter((a) => a.id !== id);
	console.log(
		'activities.value',
		activities.value.filter((a) => a.id !== id)
	);
};

const getActivities = async () => {
	try {
		const res = await $api.boarding.getActivitiesDaycareClass(
			props.boarding?.id
		);
		if (res) activities.value = res.data.data.reverse();
	} catch (err) {
		const error =
			err?.response?.data?.errors ||
			err?.message ||
			'Đã có lỗi xảy ra. Vui lòng thử lại';
		await store.dispatch('setAlertMessage', {
			message: error,
			type: 'danger',
		});
	}
};
const addActivities = async () => {
	try {
		let error = false;
		activities.value.some((activity, index) => {
			if (activity.name?.length <= 0 && index !== 0) {
				error = true;
				errorActivities.value[index] = 'Tên hoạt động không được trống';
				setTimeout(() => {
					errorActivities.value[index] = '';
				}, 2000);
			}
		});
		if (
			activities.value.length === 1 &&
			activities.value[0].name?.length <= 0
		) {
			activities.value = [];
		}
		if (error) {
			return;
		}
		let day_care_activities = activities.value.reverse().map((v) => ({
			...(v.id && { id: v.id }),
			name: v.name,
		}));
		await $api.boarding.addActivitiesToDaycareClass(props.boarding?.id, {
			day_care_activities,
		});
		await store.dispatch('setAlertMessage', {
			message: 'Chỉnh sửa hoạt động thành công',
		});
		emit('submit');
	} catch (err) {
		const error =
			err?.response?.data?.errors ||
			err?.message ||
			'Đã có lỗi xảy ra. Vui lòng thử lại';
		await store.dispatch('setAlertMessage', {
			message: error,
			type: 'danger',
		});
	}
};
</script>

<style scoped lang="scss">
.da-dropdown {
	@apply mt-2 rounded-md bg-white w-full z-20 shadow-input py-5 pl-2;
	position: absolute;

	&__wrapper {
		@apply overflow-y-auto w-full;
		height: fit-content;
		max-height: 150px;
	}

	&-item {
		@apply pl-3 py-2 cursor-pointer;
		border-radius: 5px;

		&:hover {
			@apply bg-yellow-100 text-yellow-700;
		}
	}

	.active {
		@apply bg-yellow-100 text-yellow-700;
	}
}
.disable-button {
	background: #e1e7ef !important;
	border: 1px solid #cbd5e1 !important;
	color: #cbd5e1;
}
</style>
