<template>
	<DaModalFrame v-model:open="modalIsOpen" @close="closeModelParent">
		<DeleteConfirm
			v-if="isReteach && !isEditModal && !open"
			v-model:is-no-repeat="isNoRepeatReTeach"
			v-model:successfully="isSuccess"
			:is-re-teach="true"
			@close="closeModal"
			@submit="deleteGoal(idSelected)"
		/>
		<DeleteConfirm
			v-if="!isReteach && !isEditModal && !open"
			v-model:is-no-repeat="isNoRepeat"
			v-model:successfully="isSuccess"
			@close="closeModal"
			@submit="deleteGoal(idSelected)"
		/>
		<DeleteListGoal
			v-if="open"
			v-model:is-no-repeat="isNoRepeat"
			:goalDelete="goalDelete"
			@close="closeModal"
			@submit="deleteListGoal"
		/>
	</DaModalFrame>
	<div :class="isEditModal ? 'side-over--active' : ''">
		<div
			v-if="isEditModal"
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			@click="isEditModal = false"
		></div>
		<EditGoalSideOver
			v-model:data="editData"
			v-model:error="errorEdit"
			v-model:skills="skills"
			v-model:tab-active="tabActive"
			:show="isEditModal"
			@close="closeModal"
			@submit="editSubmit"
		/>
	</div>
	<div v-if="isCompleted" class="w-full px-6">
		<div class="py-12 flex items-center justify-between">
			<div
				class="
					text-3xl
					font-bold
					text-neutral-500
					whitespace-nowrap
					md-down:text-xl
				"
			>
				Ngân hàng mục tiêu
			</div>
			<div class="cursor-pointer">
				<svg
					fill="none"
					height="28"
					viewBox="0 0 28 28"
					width="28"
					xmlns="http://www.w3.org/2000/svg"
					@click="$emit('close')"
				>
					<path
						clip-rule="evenodd"
						d="M8.71478 8.71453C9.05649 8.37282 9.61051 8.37282 9.95221 8.71453L14.5835 13.3458L19.2148 8.71453C19.5565 8.37282 20.1105 8.37282 20.4522 8.71453C20.7939 9.05624 20.7939 9.61026 20.4522 9.95197L15.8209 14.5833L20.4522 19.2145C20.7939 19.5562 20.7939 20.1103 20.4522 20.452C20.1105 20.7937 19.5565 20.7937 19.2148 20.452L14.5835 15.8207L9.95221 20.452C9.61051 20.7937 9.05649 20.7937 8.71478 20.452C8.37307 20.1103 8.37307 19.5562 8.71478 19.2145L13.3461 14.5833L8.71478 9.95197C8.37307 9.61026 8.37307 9.05624 8.71478 8.71453Z"
						fill="#94A3B8"
						fill-rule="evenodd"
					/>
				</svg>
			</div>
		</div>
		<div class="p-6 bg-white rounded-2xl">
			<div>
				<div class="flex items-center justify-between mb-4">
					<div class="font-semibold text-neutral-500">
						Thống kê chung
					</div>
					<div
						class="font-semibold text-neutral-500 text-right"
					></div>
				</div>
			</div>
			<div class="grid grid-cols-1 md:grid-cols-2">
				<div
					class="
						p-6
						border
						lg:rounded-l-lg
						md:rounded-tl-lg
						border-neutral-200
						w-full
						md-down:rounded-t md-down:rounded-b-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							alt=""
							class="mr-2"
							height="32"
							src="@/assets/images/icons/abc.png"
							style="height: 32px"
							width="32"
						/>
						{{ skills_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Tổng số lĩnh vực
						</span>
					</div>
				</div>
				<div
					class="
						p-6
						border
						md:border-l-0
						lg:rounded-r-lg
						md:rounded-br-lg
						border-neutral-200
						w-full
						lg-down:border-t-0
						md-down:rounded-b md-down:rounded-t-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							alt=""
							class="mr-2"
							height="32"
							src="@/assets/images/icons/light.png"
							style="height: 32px"
							width="32"
						/>
						{{ goals_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Tổng số mục tiêu</span
						>
					</div>
				</div>
			</div>
		</div>
		<div class="flex justify-end mt-4">
			<button
				class="delete-button flex items-center gap-1"
				:class="{ 'disable-button': goalDelete.length == 0 }"
				@click="openDelete"
			>
				Xoá mục tiêu
			</button>
		</div>
		<div>
			<div v-for="(goal, index) in goals" :key="index" class="my-10">
				<div class="font-semibold text-lg text-neutral-600 mb-4">
					{{ index + 1 }}. {{ goal.skill.name }}
				</div>
				<div class="shadow-sm sm:rounded-2xl overflow-hidden">
					<div class="__record__table overflow-x-auto scroll-bar">
						<div class="overflow-hidden sm:rounded-t-2xl">
							<table class="divide-y text-neutral-200">
								<thead class="bg-neutral-100 h-16">
									<tr>
										<th
											scope="col"
											class="
												px-6
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												w-16
												whitespace-nowrap
											"
										>
											<Checkbox
												:value="checkAll(goal.goals)"
												@click="
													addAddGoalToDelete(
														goal.goals
													)
												"
											></Checkbox>
										</th>
										<th
											class="
												px-6
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												w-16
												whitespace-nowrap
											"
											scope="col"
										>
											STT
										</th>
										<th
											class="
												px-6
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
												w-full
											"
											scope="col"
											style="min-width: 160px"
										>
											Nội dung
										</th>
									</tr>
								</thead>
								<tbody>
									<template
										v-for="(g, i) in goal.goals"
										:key="g.goal_id"
									>
										<tr
											:class="
												i % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
											class="text-neutral-700"
										>
											<td class="w-16 px-6 py-4">
												<Checkbox
													:value="
														checkStatus(g.goal_id)
													"
													@click="
														addToDelete(g.goal_id)
													"
												></Checkbox>
											</td>
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
													flex
												"
											>
												<div class="ml-2">
													{{ i + 1 }}
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
												"
											>
												<div class="flex items-center">
													<router-link
														:to="`/goal/${id}/${
															g.goal_id || g.id
														}`"
													>
														<div
															class="
																mr-2
																hover:text-yellow-700
																hover:underline
															"
															v-html="
																g.goal_name ||
																g.name
															"
														></div>
													</router-link>
													<TooltipGoalDetail
														:goal-id="
															g.goal_id || g.id
														"
														:student-id="id"
													/>
												</div>
												<div
													v-if="
														(g?.assistance_levels &&
															org.enable_goal_assistance_level) ||
														g?.raw_source
													"
													class="flex gap-2 flex-wrap"
													style="max-width: 360px"
												>
													<div
														v-if="g?.raw_source"
														class="
															mt-1
															py-0.5
															px-2.5
															rounded-full
															bg-neutral-200
															text-neutral-700
															h-fit
															w-fit
															whitespace-nowrap
															font-semibold
															shadow-input
														"
														style="font-size: 10px"
													>
														{{ g?.display_source }}
													</div>
													<template
														v-if="
															g?.assistance_levels &&
															org.enable_goal_assistance_level
														"
													>
														<div
															v-for="ass in g?.assistance_levels"
															:key="ass"
															class="flex gap-2"
														>
															<div
																class="
																	mt-1
																	py-0.5
																	px-2.5
																	rounded-full
																	bg-pink-100
																	whitespace-nowrap
																	text-neutral-700
																	h-fit
																	w-fit
																	font-semibold
																	shadow-input
																"
																style="
																	font-size: 10px;
																"
															>
																{{ ass }}
															</div>
														</div>
													</template>
												</div>
												<div
													v-if="
														g.parent
															?.sub_goal_count > 0
													"
													class="
														goal-block
														flex
														gap-3
														mt-2
													"
												>
													<img
														class="w-3 h-3 mt-1"
														src="@/assets/images/icons/goal-arrow.png"
														alt=""
													/>
													<div>
														<div
															class="
																text-neutral-700
															"
														>
															{{ g.parent?.name }}
														</div>
														<div
															class="
																text-neutral-400
																text-xs
																font-semibold
															"
														>
															{{
																g.parent
																	?.sub_goal_count
															}}
															MỤC TIÊU
														</div>
													</div>
												</div>
												<div class="mt-6">
													<div
														class="
															flex
															items-center
															justify-end
															md-down:flex-col
															md-down:items-end
															md-down:gap-4
														"
													>
														<div
															class="
																cursor-pointer
																text-yellow-600
																hover:text-yellow-700
																font-semibold
																text-sm
																flex
																items-center
																md-down:items-right
																capitalize
															"
															@click="
																deleting(
																	g.goal_id
																)
															"
														>
															<svg
																class="mr-2"
																fill="none"
																height="16"
																viewBox="0 0 16 16"
																width="16"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	clip-rule="evenodd"
																	d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
																	fill="currentColor"
																	fill-rule="evenodd"
																/>
															</svg>
															XOÁ
														</div>
														<div
															class="
																cursor-pointer
																text-yellow-600
																hover:text-yellow-700
																font-semibold
																text-sm
																flex
																items-center
																md-down:items-right
																whitespace-nowrap
																ml-6
																capitalize
															"
															@click="
																editGoalModal(g)
															"
														>
															<svg
																class="mr-2"
																fill="none"
																height="16"
																viewBox="0 0 16 16"
																width="16"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	clip-rule="evenodd"
																	d="M12.4702 6.65087L13.7102 5.41084C13.8461 5.27498 13.914 5.20705 13.9667 5.14509C14.6009 4.39841 14.6009 3.30223 13.9667 2.55555C13.914 2.49359 13.8461 2.42566 13.7102 2.2898C13.5744 2.15394 13.5064 2.08601 13.4445 2.03338C12.6978 1.39915 11.6016 1.39915 10.855 2.03338C10.793 2.086 10.7251 2.15391 10.5893 2.2897L10.5892 2.28978L10.5892 2.2898L9.33099 3.54801C10.0747 4.84221 11.1583 5.91748 12.4702 6.65087ZM7.87633 5.00267L2.86273 10.0163C2.43767 10.4413 2.22514 10.6539 2.0854 10.915C1.94567 11.1761 1.88672 11.4708 1.76883 12.0602L1.37318 14.0385C1.30666 14.3711 1.27339 14.5374 1.368 14.632C1.46261 14.7266 1.62892 14.6934 1.96153 14.6269L3.9398 14.2312L3.93982 14.2312L3.93984 14.2312C4.52927 14.1133 4.82399 14.0544 5.08508 13.9146C5.34617 13.7749 5.55869 13.5624 5.98373 13.1373L5.98376 13.1373L5.98377 13.1373L11.0118 8.10931C9.7511 7.3111 8.68202 6.24922 7.87633 5.00267Z"
																	fill="#E5A42B"
																	fill-rule="evenodd"
																/>
															</svg>
															CHỈNH SỬA
														</div>
													</div>
												</div>
											</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="w-full px-6">
		<div class="py-12 flex items-center justify-between">
			<div class="text-3xl font-bold text-neutral-500 whitespace-nowrap">
				Mục tiêu hoàn thành
			</div>
			<div class="cursor-pointer">
				<svg
					fill="none"
					height="28"
					viewBox="0 0 28 28"
					width="28"
					xmlns="http://www.w3.org/2000/svg"
					@click="$emit('close')"
				>
					<path
						clip-rule="evenodd"
						d="M8.71478 8.71453C9.05649 8.37282 9.61051 8.37282 9.95221 8.71453L14.5835 13.3458L19.2148 8.71453C19.5565 8.37282 20.1105 8.37282 20.4522 8.71453C20.7939 9.05624 20.7939 9.61026 20.4522 9.95197L15.8209 14.5833L20.4522 19.2145C20.7939 19.5562 20.7939 20.1103 20.4522 20.452C20.1105 20.7937 19.5565 20.7937 19.2148 20.452L14.5835 15.8207L9.95221 20.452C9.61051 20.7937 9.05649 20.7937 8.71478 20.452C8.37307 20.1103 8.37307 19.5562 8.71478 19.2145L13.3461 14.5833L8.71478 9.95197C8.37307 9.61026 8.37307 9.05624 8.71478 8.71453Z"
						fill="#94A3B8"
						fill-rule="evenodd"
					/>
				</svg>
			</div>
		</div>
		<div class="p-6 bg-white rounded-2xl">
			<div>
				<div class="flex items-center justify-between mb-4">
					<div class="font-semibold text-neutral-500">
						Thống kê chung
					</div>
				</div>
			</div>
			<div class="grid grid-cols-1 md:grid-cols-2">
				<div
					class="
						p-3
						border
						md:rounded-l-lg
						border-neutral-200
						w-full
						md-down:rounded-t md-down:rounded-b-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							flex flex-nowrap
							items-center
						"
					>
						<img
							alt=""
							class="mr-2 mb-2"
							height="32"
							src="@/assets/images/icons/abc.png"
							style="height: 32px"
							width="32"
						/>
						{{ skills_count__completed }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Tổng số lĩnh vực
						</span>
					</div>
				</div>
				<div
					class="
						p-3
						border
						md-down:border-t-0
						md:border-l-0 md:rounded-r-lg
						border-neutral-200
						w-full
						md-down:rounded-b md-down:rounded-t-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-1
							flex flex-nowrap
							items-center
						"
					>
						<img
							alt=""
							class="mr-2 mb-2"
							height="32"
							src="@/assets/images/icons/light.png"
							style="height: 32px"
							width="32"
						/>
						{{ goals_count__completed }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Tổng mục tiêu
						</span>
					</div>
				</div>
			</div>
		</div>
		<div>
			<div
				v-for="(goal, index) in goals__completed"
				:key="index"
				class="my-10"
			>
				<div class="font-semibold text-lg text-neutral-600 mb-4">
					{{ index + 1 }}. {{ goal.skill.name }} ({{
						goal.goals.length
					}})
				</div>
				<div class="shadow-sm sm:rounded-2xl overflow-hidden">
					<div class="__record__table overflow-x-auto scroll-bar">
						<div class="overflow-hidden sm:rounded-t-2xl">
							<table class="divide-y text-neutral-200">
								<thead class="bg-neutral-100 h-16">
									<tr>
										<th
											class="
												px-6
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												w-16
												whitespace-nowrap
											"
											scope="col"
										>
											STT
										</th>
										<th
											class="
												px-6
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
												w-full
											"
											scope="col"
											style="min-width: 160px"
										>
											Nội dung
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(g, i) in goal.goals"
										:key="g.goal_id"
										:class="
											i % 2 === 0
												? 'bg-white'
												: 'bg-neutral-50'
										"
										class="text-neutral-700"
									>
										<td
											class="
												px-6
												py-4
												whitespace-nowrap
												text-neutral-700
											"
										>
											<div class="ml-2">
												{{ i + 1 }}
											</div>
										</td>
										<td class="px-6 py-4 text-neutral-700">
											<div class="flex items-center">
												<router-link
													:to="`/goal/${id}/${g.id}`"
												>
													<div class="mr-2">
														{{ g.name }}
													</div>
												</router-link>
												<TooltipGoalDetail
													:goal-id="g.goal_id || g.id"
													:student-id="id"
												/>
											</div>
											<div
												v-if="
													(g?.assistance_levels &&
														org.enable_goal_assistance_level) ||
													g?.raw_source
												"
												class="flex gap-2 flex-wrap"
												style="max-width: 360px"
											>
												<div
													v-if="g?.raw_source"
													class="
														mt-1
														py-0.5
														px-2.5
														rounded-full
														bg-neutral-200
														text-neutral-700
														h-fit
														w-fit
														whitespace-nowrap
														font-semibold
														shadow-input
													"
													style="font-size: 10px"
												>
													{{ g?.display_source }}
												</div>
												<template
													v-if="
														g?.assistance_levels &&
														org.enable_goal_assistance_level
													"
												>
													<div
														v-for="ass in g?.assistance_levels"
														:key="ass"
														class="flex gap-2"
													>
														<div
															class="
																mt-1
																py-0.5
																px-2.5
																rounded-full
																bg-pink-100
																whitespace-nowrap
																text-neutral-700
																h-fit
																w-fit
																font-semibold
																shadow-input
															"
															style="
																font-size: 10px;
															"
														>
															{{ ass }}
														</div>
													</div>
												</template>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { $api } from '@/services';
import { useStore } from 'vuex';
import { goalLibByStudent, useStudent } from '@/features/student/useStudent';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import DeleteConfirm from '@/components/modules/reportTargetFuture/DeleteConfirm.vue';
import EditGoalSideOver from '@/components/elements/editGoalSideOver/EditGoalSideOver.vue';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';
import Checkbox from '@/components/elements/checkbox/Checkbox';
import DeleteListGoal from './DeleteListGoal.vue';

export default defineComponent({
	name: 'PlanFutureSlideOver',
	components: {
		TooltipGoalDetail,
		EditGoalSideOver,
		DeleteConfirm,
		DaModalFrame,
		Checkbox,
		DeleteListGoal,
	},
	props: {
		isCompleted: {
			type: Boolean,
			default: false,
		},
		isUpdated: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { emit }) {
		const route = useRoute();
		const store = useStore();

		const id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		//
		const data = ref(null);
		const goals = ref(null);
		const goals_count = ref(0);
		const skills_count = ref(0);
		const continued_goals_count = ref(0);
		const goals_from_lib_count = ref(0);
		const goals_from_stopped_count = ref(0);
		const new_goals_count = ref(0);
		const goalDelete = ref([]);
		const open = ref(false);

		function checkAll(data) {
			if (data.every((d) => goalDelete.value.includes(d.goal_id)))
				return true;
			else return false;
		}
		function addAddGoalToDelete(data) {
			if (data.every((d) => goalDelete.value.includes(d.goal_id))) {
				data.map((d) => {
					goalDelete.value = goalDelete.value.filter((item) => {
						return item !== d.goal_id;
					});
				});
			} else data.map((d) => goalDelete.value.push(d.goal_id));
		}
		function addToDelete(id) {
			const result = goalDelete.value.find((item) => item === id);
			if (result) {
				goalDelete.value = goalDelete.value.filter((item) => {
					return item !== id;
				});
			} else {
				goalDelete.value.push(id);
			}
			checkAll;
		}
		function checkStatus(data) {
			if (goalDelete.value.includes(data)) return true;
			else return false;
		}
		function openDelete() {
			modalIsOpen.value = true;
			open.value = true;
		}
		async function deleteListGoal() {
			try {
				await $api.goal.deleteGoalLib(id.value, goalDelete.value);
				await fetchGoalsLib();
				await store.dispatch('setAlertMessage', {
					message: 'Đã xoá mục tiêu thành công',
				});
				isSuccess.value = true;
				goalDelete.value = [];
				closeModal();
			} catch (e) {
				await store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		}

		async function fetchGoalsLib() {
			const res = await goalLibByStudent(id.value);
			if (res?.data) {
				goals.value = res?.data?.skills ?? [];

				if (res?.stats) {
					skills_count.value = res?.stats?.skills_count || 0;
					goals_count.value = res?.stats?.goals_count || 0;
				}
			}
		}

		const student_id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		async function deleteGoal(id) {
			try {
				await $api.goal.deleteGoalLib(id, student_id.value);
				await fetchGoalsLib();
				await store.dispatch('setAlertMessage', {
					message: 'Đã xoá mục tiêu thành công',
				});
				isSuccess.value = true;

				closeModal();
				goalDelete.value = [];
			} catch (e) {
				await store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		}

		watch(
			() => props.isUpdated,
			(value) => {
				if (value) {
					fetchGoalsLib();
					emit('updated');
				}
			}
		);

		//
		const { goalCompletedByStudent } = useStudent();

		const goals__completed = ref([]);
		const skills_count__completed = ref(0);
		const goals_count__completed = ref(0);

		//
		async function fetchGoals() {
			const res = await goalCompletedByStudent(id.value);
			if (res?.data) {
				goals__completed.value = res?.data.skills;
				skills_count__completed.value = goals__completed.value.length;
				if (goals__completed.value.length > 0) {
					goals__completed.value.forEach((item) => {
						goals_count__completed.value += item?.goals.length || 0;
					});
				}
			}
		}

		const modalIsOpen = ref(false);
		const idSelected = ref('');
		const isSuccess = ref(false);
		const isNoRepeat = ref(false);
		const isReteach = ref(false);
		const isNoRepeatReTeach = ref(false);
		const isEditModal = ref(false);

		function deleting(id, reteach = false) {
			if (reteach && isNoRepeatReTeach.value) {
				deleteGoal(id);
				closeModal();
				return;
			}
			if (!reteach && isNoRepeat.value) {
				deleteGoal(id);
				closeModal();
				return;
			}
			idSelected.value = id;
			modalIsOpen.value = true;
			isSuccess.value = false;
			isEditModal.value = false;
			isReteach.value = reteach;
		}

		let editData = ref(null);
		const tabActive = ref(null);

		async function editGoalModal(data) {
			console.log(data);
			editData.value = {
				id: data?.id || data?.goal_id,
				name: data?.name || data?.goal_name,
			};
			isEditModal.value = true;
			if (!data?.editable) {
				tabActive.value = 0;
			} else {
				tabActive.value = null;
			}
		}

		function closeModal() {
			modalIsOpen.value = false;
			idSelected.value = '';

			isSuccess.value = false;
			isEditModal.value = false;
		}

		function closeModelParent() {
			if (isReteach.value) {
				isNoRepeatReTeach.value = false;
			} else {
				isNoRepeat.value = false;
			}
			closeModal();
		}

		const errorEdit = ref('');

		async function editSubmit(data) {
			try {
				const res = await $api.goal.updateGoal(id.value, data.id, {
					goal: data,
				});
				if (res?.data) {
					closeModal();
					await fetchGoalsLib();
					await store.dispatch('setAlertMessage', {
						message: 'Chỉnh sửa mục tiêu thành công',
					});
					emit('re-fetch');
				}
			} catch (e) {
				errorEdit.value = e?.response?.data?.errors_by_field || '';
			}
		}

		const skills = ref([]);

		async function fetchSkill() {
			try {
				const res = await $api.goal.allSkills();
				if (res?.data) {
					skills.value = res?.data?.data;
				}
			} catch (e) {
				console.log(e);
			}
		}

		function openSubGoal(goal) {
			if (goal.sub_goal_count > 0) goal.open = !goal.open;
			else return;
		}

		fetchGoals();
		fetchGoalsLib();
		fetchSkill();

		return {
			id,
			isReteach,
			isNoRepeat,
			isNoRepeatReTeach,
			modalIsOpen,
			idSelected,
			isSuccess,
			errorEdit,
			editData,
			isEditModal,
			skills,
			deleting,
			closeModal,
			closeModelParent,
			editSubmit,
			editGoalModal,
			data,
			goals,
			goals_count,
			skills_count,
			continued_goals_count,
			goals_from_lib_count,
			goals_from_stopped_count,
			new_goals_count,
			goals__completed,
			skills_count__completed,
			goals_count__completed,
			stop,
			deleteGoal,
			tabActive,
			org: computed(() => store.state.profile.current_org),
			openSubGoal,
			goalDelete,
			deleteListGoal,
			checkStatus,
			addToDelete,
			checkAll,
			addAddGoalToDelete,
			open,
			openDelete,
		};
	},
});
</script>
<style scoped>
.goal-block {
	background: #eef3f8;
	padding: 12px;
	border-radius: 8px;
	border: 1px solid #bacde4;
}
.delete-button {
	padding: 12px 20px;
	gap: 6px;
	border-radius: 4px;
	border: 1px solid #d89210;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	color: #d89210;
}
.disable-button {
	background: #e1e7ef !important;
	border: 1px solid #cbd5e1 !important;
	color: #cbd5e1;
}
</style>
