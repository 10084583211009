<template>
	<DaModalFrame v-model:open="modalIsOpen">
		<DeleteTwoLayerGoal :goal="goal" @close="cancel" @submit="deleteGoal" />
	</DaModalFrame>
	<div class="shadow-sm sm:rounded-2xl">
		<div class="sm:rounded-t-2xl w-fit min-w-full">
			<table class="min-w-full divide-y text-neutral-200">
				<thead class="bg-neutral-100 h-16">
					<tr>
						<th
							scope="col"
							class="
								px-6
								py-3
								text-left text-xs
								font-semibold
								text-neutral-600
								uppercase
								tracking-wider
								w-8
								whitespace-nowrap
							"
						></th>
						<th
							class="
								px-6
								py-3
								text-left text-xs
								font-semibold
								text-neutral-600
								uppercase
								tracking-wider
								w-16
								whitespace-nowrap
							"
							scope="col"
						>
							STT
						</th>
						<th
							class="
								px-6
								py-3
								text-left text-xs
								font-semibold
								text-neutral-600
								uppercase
								tracking-wider
								whitespace-nowrap
							"
							scope="col"
						>
							Lĩnh vực
						</th>
						<th
							class="
								px-6
								py-3
								text-left text-xs
								font-semibold
								text-neutral-600
								uppercase
								tracking-wider
								whitespace-nowrap
							"
							scope="col"
							style="min-width: 160px"
						>
							Nội dung
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<template
						v-for="(group, index) in props.data"
						:key="group.id"
					>
						<template
							v-for="(g, subIndex) in group.goals"
							:key="g.goal_id || g.id"
						>
							<tr
								:class="
									getIndex(index, subIndex) % 2 === 0
										? 'bg-neutral-50'
										: 'bg-white'
								"
								class="text-neutral-700 cursor-pointer"
							>
								<td
									class="px-6 py-4 w-8"
									@click.stop="openSubGoal(g)"
								>
									<div
										v-if="g.sub_goal_count > 0"
										class="w-4"
									>
										<img
											class="w-full"
											v-if="g.open"
											src="@/assets/images/icons/arrow-up.png"
											alt=""
										/>
										<img
											class="w-full"
											v-else
											src="@/assets/images/icons/arrow-down.png"
											alt=""
										/>
									</div>
								</td>
								<td
									class="
										px-6
										py-4
										whitespace-nowrap
										text-neutral-700 text-center
									"
								>
									{{ getIndex(index, subIndex) }}
								</td>
								<td class="px-6 py-4 text-neutral-700">
									{{ g?.skill_name }}
								</td>
								<td class="px-6 py-4 text-neutral-700">
									<div class="flex items-center">
										<div
											class="mr-2"
											v-html="g.goal_name || g.name"
										></div>
										<TooltipGoalDetail
											v-if="g.sub_goal_count == 0"
											:goal-id="g.goal_id || g.id"
											:is-group="isGroup"
											:student-id="
												props.studentId.toString()
											"
										/>
									</div>
									<div
										v-if="g?.sub_goal_count > 0"
										class="mt-2"
									>
										<div
											class="
												text-neutral-400 text-xs
												font-semibold
											"
										>
											{{ g?.sub_goal_count }}
											MỤC TIÊU
										</div>
									</div>
									<div class="flex items-center gap-2 mt-2">
										<div v-if="g?.has_media">
											<img
												alt=""
												src="@/assets/images/icons/has_media.png"
											/>
										</div>
										<div
											v-if="g.number_of_assessment > 0"
											class="
												text-neutral-400 text-xs
												font-semibold
											"
										>
											ĐÃ HỌC
											{{ g.number_of_assessment }}
											NGÀY
										</div>
										<div v-if="g?.completed_at">
											<img
												alt=""
												src="@/assets/images/icons/has_taught.png"
											/>
										</div>
									</div>
									<div class="flex items-center gap-2 mt-2">
										<div
											class="
												text-neutral-400 text-xs
												font-semibold
											"
										>
											KẾT QUẢ
										</div>
										<div
											class="progress-bar overflow-hidden"
										>
											<div
												:style="{
													background: BAR_COLOR(
														g.complete_percent
													),
													width:
														g.complete_percent +
														'%',
												}"
												class="h-full"
											></div>
										</div>
										<div
											class="
												text-neutral-400 text-xs
												font-semibold
											"
										>
											{{ g.complete_percent }}%
										</div>
									</div>
								</td>
								<td class="px-6 py-4">
									<Menu as="div" class="ml-3 relative">
										<div>
											<MenuButton
												class="
													rounded-full
													flex
													text-sm
													focus:outline-none
													focus:ring-2
													focus:ring-offset-2
													focus:ring-indigo-500
												"
											>
												<span class="sr-only"
													>Open user menu</span
												>
												<svg
													fill="none"
													height="20"
													viewBox="0 0 20 20"
													width="20"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														clip-rule="evenodd"
														d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
														fill="#94A3B8"
														fill-rule="evenodd"
													/>
												</svg>
											</MenuButton>
										</div>
										<transition
											enter-active-class="transition ease-out duration-200"
											enter-from-class="transform opacity-0 scale-95"
											enter-to-class="transform opacity-100 scale-100"
											leave-active-class="transition ease-in duration-75"
											leave-from-class="transform opacity-100 scale-100"
											leave-to-class="transform opacity-0 scale-95"
										>
											<MenuItems
												class="
													origin-top-right
													absolute
													right-0
													mt-2
													w-60
													rounded-md
													py-1
													bg-white
													focus:outline-none
													z-10
													shadow-sm
													border border-yellow-100
												"
											>
												<MenuItem
													v-slot="{ active }"
													@click="
														$router.push(
															`/create-goal?student_id=${props.studentId}&goal_id=${g.goal_id}`
														)
													"
												>
													<div
														:class="
															active ? '' : ''
														"
														class="
															p-2
															px-4
															bg-white
															z-10
															text-gray-600
															hover:bg-neutral-50
															flex
															items-center
															gap-4
															cursor-pointer
														"
													>
														<svg
															fill="none"
															height="16"
															viewBox="0 0 16 16"
															width="16"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																clip-rule="evenodd"
																d="M12.4705 6.65175L13.7105 5.41172C13.8463 5.27586 13.9143 5.20793 13.9669 5.14597C14.6011 4.39929 14.6011 3.30311 13.9669 2.55644C13.9143 2.49448 13.8463 2.42655 13.7105 2.29069C13.5746 2.15482 13.5067 2.08689 13.4447 2.03426C12.6981 1.40004 11.6019 1.40004 10.8552 2.03426C10.7933 2.08688 10.7253 2.15479 10.5895 2.29059L10.5895 2.29067L10.5894 2.29069L9.33123 3.5489C10.0749 4.84309 11.1586 5.91837 12.4705 6.65175ZM7.87657 5.00356L2.86297 10.0172C2.43791 10.4422 2.22538 10.6547 2.08565 10.9158C1.94591 11.1769 1.88697 11.4717 1.76908 12.0611L1.37342 14.0394C1.3069 14.372 1.27364 14.5383 1.36825 14.6329C1.46286 14.7275 1.62916 14.6943 1.96177 14.6277L3.94005 14.2321L3.94007 14.2321L3.94009 14.2321C4.52951 14.1142 4.82423 14.0553 5.08532 13.9155C5.34641 13.7758 5.55894 13.5633 5.98398 13.1382L5.984 13.1382L5.98401 13.1382L11.012 8.11019C9.75135 7.31198 8.68226 6.25011 7.87657 5.00356Z"
																fill="#94A3B8"
																fill-rule="evenodd"
															/>
														</svg>

														<span class="mt-0.5"
															>Chỉnh sửa</span
														>
													</div>
												</MenuItem>
												<MenuItem
													v-slot="{ active }"
													@click="openModal(g)"
												>
													<div
														:class="
															active ? '' : ''
														"
														class="
															p-2
															px-4
															bg-white
															z-10
															text-gray-600
															hover:bg-neutral-50
															flex
															items-center
															gap-4
															cursor-pointer
														"
													>
														<svg
															width="14"
															height="15"
															viewBox="0 0 14 15"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M1.5 1C0.947715 1 0.5 1.44772 0.5 2V3C0.5 3.55228 0.947715 4 1.5 4H2V13C2 14.1046 2.89543 15 4 15H10C11.1046 15 12 14.1046 12 13V4H12.5C13.0523 4 13.5 3.55228 13.5 3V2C13.5 1.44772 13.0523 1 12.5 1H9C9 0.447715 8.55229 0 8 0H6C5.44772 0 5 0.447715 5 1H1.5ZM4.5 5C4.77614 5 5 5.22386 5 5.5V12.5C5 12.7761 4.77614 13 4.5 13C4.22386 13 4 12.7761 4 12.5L4 5.5C4 5.22386 4.22386 5 4.5 5ZM7 5C7.27614 5 7.5 5.22386 7.5 5.5V12.5C7.5 12.7761 7.27614 13 7 13C6.72386 13 6.5 12.7761 6.5 12.5V5.5C6.5 5.22386 6.72386 5 7 5ZM10 5.5C10 5.22386 9.77614 5 9.5 5C9.22386 5 9 5.22386 9 5.5V12.5C9 12.7761 9.22386 13 9.5 13C9.77614 13 10 12.7761 10 12.5V5.5Z"
																fill="#94A3B8"
															/>
														</svg>

														<span class="mt-0.5"
															>Xoá mục tiêu
															chung</span
														>
													</div>
												</MenuItem>
											</MenuItems>
										</transition>
									</Menu>
								</td>
							</tr>
							<tr
								class="text-neutral-700 cursor-pointer"
								:class="
									i % 2 === 0 ? 'bg-white' : 'bg-neutral-50'
								"
								v-if="g.open"
							>
								<td colspan="5" class="pl-16">
									<child-goal
										:data="g.sub_goals"
										:id="props.studentId"
									/>
								</td>
							</tr>
						</template>
					</template>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script setup>
import { computed, defineProps, ref, defineEmits } from 'vue';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';
import ChildGoal from '@/components/elements/ChildGoal.vue';
import { BAR_COLOR } from '@/helper/tag';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { useStore } from 'vuex';
import DeleteTwoLayerGoal from './DeleteTwoLayerGoal.vue';
import { $api } from '@/services';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';

const goal = ref({});
const modalIsOpen = ref(false);
const store = useStore();
const props = defineProps(['data', 'studentId']);
const emit = defineEmits(['refresh']);

const org = computed(() => store.state.profile.current_org);

function openSubGoal(goal) {
	if (goal.sub_goal_count > 0) goal.open = !goal.open;
	else return;
}

function getIndex(index, subIndex) {
	let previousLength = 0;
	for (let i = 0; i < index; i++) {
		previousLength += props.data[i].goals.length;
	}
	return previousLength + subIndex + 1;
}

function openModal(data) {
	goal.value = data;
	modalIsOpen.value = true;
}

function cancel() {
	modalIsOpen.value = false;
}

async function deleteGoal() {
	try {
		const res = await $api.goal.deleteGoalLib(
			goal.value.goal_id,
			props.studentId
		);
		if (res.data) {
			cancel();
			await store.dispatch('setAlertMessage', {
				message: 'Đã xoá thành công mục tiêu chung',
			});
			emit('refresh');
		}
	} catch (err) {
		await store.dispatch('setAlertMessage', {
			message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
			type: 'danger',
		});
	}
}
</script>
