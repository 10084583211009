<script setup>
import { $api } from '@/services';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { _GET_DATE_2 } from '@/helper/formatDate';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import ResultModal from '@/views/record/studentDetail/features/plan/ResultModal.vue';
import router from '@/router';
import Loading from '@/components/common/Loading';

const route = useRoute();
const isShow = ref(false);
const loading = ref(false);
const empty = ref(false);
const data = ref([]);
const statistics = ref([]);
const result = ref([]);
const emotion_cooperation_result_by_day = ref([]);
const startDate = ref('');
const endDate = ref('');
const chartOptions = ref({
	dataLabels: {
		enabled: false,
	},
	colors: ['#7AD2C6', '#93BF56', '#F8BB54', '#F47C7C', '#E5E7EB'],
	labels: [
		'Đạt 80 - 100%',
		'Đạt 55 - 75%',
		'Đạt 30 - 50%',
		'Đạt 5 - 25%',
		'X',
	],
	legend: {
		show: false,
	},
	tooltip: {
		fillSeriesColor: true,
		y: {
			formatter: function (value) {
				return value + ' mục tiêu';
			},
		},
	},
	stroke: {
		lineCap: 'round',
	},
});
const chartOptions3 = ref({
	colors: ['#9EDF93', '#D0DB54', '#FFE68C', '#FF9D6D', '#FF6967'],
	chart: {
		width: 380,
		type: 'polarArea',
	},
	labels: ['Vui vẻ', 'Bình thường', 'Khó chịu', 'Tức giận', 'Bùng nổ'],
	fill: {
		opacity: 1,
		colors: ['#9EDF93', '#D0DB54', '#FFE68C', '#FF9D6D', '#FF6967'],
	},
	stroke: {
		lineCap: 'round',
	},
	yaxis: {
		show: false,
	},
	tooltip: {
		fillSeriesColor: true,
		y: {
			formatter: function (value) {
				if (!value || value == 'null') {
					return 'Không thực hiện';
				}
				return value + '%';
			},
		},
	},
	legend: {
		show: false,
	},
	dataLabels: {
		enabled: false,
	},
	plotOptions: {
		polarArea: {
			rings: {
				strokeWidth: 0,
			},
			spokes: {
				strokeWidth: 0,
			},
		},
	},
});
const mixChartOptions = ref({
	chart: {
		type: 'line',
		stacked: false,
	},
	dataLabels: {
		enabled: true,
		enabledOnSeries: [0, 1],
		offsetY: -10,
		background: {
			enabled: true,
			foreColor: '#334155',
		},
		formatter: function (val) {
			return val + '%';
		},
	},
	stroke: {
		width: [0.5, 0.5, 4],
	},
	yaxis: [
		{
			opposite: false,
			axisTicks: {
				show: false,
			},
			axisBorder: {
				show: false,
			},
			labels: {
				show: false,
				formatter: function (value) {
					if (!value || value == 'null') {
						return 'Không thực hiện';
					}
					return value + '%';
				},
			},
			title: {
				show: false,
			},
			max: 100,
		},
		{
			opposite: false,
			axisTicks: {
				show: false,
			},
			axisBorder: {
				show: false,
			},
			labels: {
				show: false,
				formatter: function (value) {
					if (!value || value == 'null') {
						return 'Không thực hiện';
					}
					return value + '%';
				},
			},
			title: {
				show: false,
			},
			max: 100,
		},
		{
			opposite: false,
			axisTicks: {
				show: false,
			},
			axisBorder: {
				show: false,
			},
			labels: {
				show: false,
				formatter: function (value) {
					if (!value || value == 'null') {
						return 'Không thực hiện';
					}
					return value + '%';
				},
			},
			title: {
				show: false,
			},
			max: 100,
		},
	],
	tooltip: {
		fixed: {
			enabled: true,
			position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
			offsetY: 30,
			offsetX: 60,
		},
	},
	legend: {
		horizontalAlign: 'left',
		offsetX: 40,
	},
	plotOptions: {
		bar: {
			horizontal: false,
			columnWidth: '20%',
			borderRadius: 4,
		},
	},
	labels: [],
	colors: ['#FFD270', '#DA8F8F', '#6ED0B9'],
	markers: {
		size: 8,
		strokeColors: '#fff',
		strokeWidth: 2,
	},
});
const emotion_cooperation_result_by_day_data = ref([
	{
		name: 'Cảm xúc',
		type: 'column',
		data: [0, 0, 0, 0, 0],
	},
	{
		name: 'Sự tham gia',
		type: 'column',
		data: [0, 0, 0, 0, 0],
	},
	{
		name: 'Kết quả buổi học',
		type: 'line',
		data: [0, 0, 0, 0, 0],
	},
]);
const emotion_cooperation_result_by_day_emotion = ref([]);
const emotion_cooperation_result_by_day_rating = ref([]);
const emotion_cooperation_result_by_day_result = ref([]);
const emotion_cooperation_result_by_day_labels = ref([]);
const mixChart = ref(null);
const radarChartOptions = ref({
	chart: {
		id: 'radarChart',
		type: 'radar',
	},
	title: {
		show: false,
	},
	stroke: {
		width: 0,
	},
	fill: {
		opacity: 0.2,
		colors: ['#65A30D'],
	},
	markers: {
		size: 5,
		hover: {
			size: 10,
		},
		colors: ['#65A30D'],
	},
	dataLabels: {
		enabled: true,
		formatter: function (val) {
			return val + '%';
		},
		style: {
			fontFamily: 'Gilrory',
			fontSize: '20px',
			fontWeight: 'bold',
			colors: ['#344256'],
		},
		background: {
			enabled: false,
		},
	},
	yaxis: {
		show: false,
		logBase: 100,
		tickAmount: 10,
		min: 0,
		max: 100,
		labels: {
			show: false,
		},
	},
	xaxis: {
		categories: [],
		labels: {
			show: true,
			style: {
				fontFamily: 'Gilrory',
				fontSize: '14px',
				fontWeight: 600,
				colors: Array(1000).fill('#344256'),
			},
			formatter: function (val, index) {
				return index?.dataPointIndex + 1;
			},
		},
	},
	tooltip: {
		x: {
			show: true,
			formatter: function (val, object) {
				return `${object?.dataPointIndex + 1}: ${val}`;
			},
			title: {
				formatter: (seriesName) => seriesName,
			},
		},
		y: {
			show: true,
			formatter: function (val) {
				return val + '%';
			},
			title: {
				title: {
					formatter: (seriesName) => seriesName + ':',
				},
			},
		},
	},
});
const series = ref([]);
const series3 = ref([]);
const radarData = ref([]);
const categories = ref([]);
const assessment_results = ref([]);
watch([startDate, endDate], () => {
	if (startDate.value && endDate.value) fetchPeriodsReport();
});
watch(
	mixChart,
	() => {
		const optimalColumnWidthPercent =
			12 +
			16 /
				(1 +
					30 *
						Math.exp(
							-emotion_cooperation_result_by_day_emotion.value
								?.length / 3
						));

		setTimeout(() => {
			mixChart.value?.updateOptions({
				plotOptions: {
					bar: {
						columnWidth: optimalColumnWidthPercent + '%',
					},
				},
			});
		}, 1000);
	},
	{ immediate: true, deep: true }
);
const formatDate = (dateString, reverse) => {
	const date = new Date(dateString);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	let formattedDate;
	if (reverse) formattedDate = `${day}/${month}/${year}`;
	else formattedDate = `${year}-${month}-${day}`;
	return formattedDate;
};
const fetchPeriodsReport = async () => {
	loading.value = true;
	try {
		emotion_cooperation_result_by_day.value = [];
		emotion_cooperation_result_by_day_emotion.value = [];
		emotion_cooperation_result_by_day_rating.value = [];
		emotion_cooperation_result_by_day_result.value = [];
		emotion_cooperation_result_by_day_labels.value = [];
		emotion_cooperation_result_by_day_data.value = [
			{
				name: 'Kết quả buổi học',
				type: 'line',
				data: [0, 0, 0, 0, 0],
			},
			{
				name: 'Sự tham gia',
				type: 'column',
				data: [0, 0, 0, 0, 0],
			},
			{
				name: 'Cảm xúc',
				type: 'column',
				data: [0, 0, 0, 0, 0],
			},
		];
		const res = await $api.monthlyReport.periodReports(
			route.params.id,
			formatDate(startDate.value),
			formatDate(endDate.value)
		);
		if (res) {
			data.value = res.data;
			statistics.value = res.data.data;
			if (
				statistics.value?.goal_count === 0 &&
				data.value?.stats?.attendance === 0
			) {
				empty.value = true;
				loading.value = false;
				return;
			}
			emotion_cooperation_result_by_day.value =
				res.data.stats?.emotion_cooperation_result_by_day;
			assessment_results.value = res.data.skills;
			let a = [];
			let count = 0;
			result.value = res.data.stats?.result;
			result.value.forEach((item) => {
				if (
					item.result === 'Từ chối' ||
					item.result === 'Không có cơ hội' ||
					item.result === 'Không hợp tác'
				) {
					count += item.count;
				} else {
					a.push(item);
				}
			});
			a.push({
				result: 'X',
				count: count,
			});
			series.value = a.map((item) => {
				return item.count;
			});
			let series3Total = 0;
			res.data.stats?.emotion_scale.forEach((item) => {
				return (series3Total += item.count);
			});
			series3.value = res.data.stats?.emotion_scale.map((item) => {
				const r = (item.count * 100) / series3Total;
				return Math.round(r * 10) / 10 || 0;
			});
			emotion_cooperation_result_by_day.value.forEach((item) => {
				emotion_cooperation_result_by_day_emotion.value.push(
					+(+item.emotion_scale / 5) * 100 || null
				);
				emotion_cooperation_result_by_day_rating.value.push(
					+(+item.cooperation_scale / 5) * 100 || null
				);
				emotion_cooperation_result_by_day_result.value.push(
					+item.complete_percent || null
				);
				emotion_cooperation_result_by_day_labels.value.push(
					_GET_DATE_2(item.date, true)
				);
			});
			emotion_cooperation_result_by_day_data.value[1] = {
				name: 'Cảm xúc',
				type: 'column',
				data: emotion_cooperation_result_by_day_emotion.value,
			};
			emotion_cooperation_result_by_day_data.value[0] = {
				name: 'Sự tham gia',
				type: 'column',
				data: emotion_cooperation_result_by_day_rating.value,
			};
			emotion_cooperation_result_by_day_data.value[2] = {
				name: 'Kết quả buổi học',
				type: 'line',
				data: emotion_cooperation_result_by_day_result.value,
			};
			const optimalColumnWidthPercent =
				5 +
				15 /
					(1 +
						30 *
							Math.exp(
								-emotion_cooperation_result_by_day_labels.value
									?.length / 3
							));

			setTimeout(() => {
				mixChart.value?.updateOptions({
					labels: emotion_cooperation_result_by_day_labels.value,
					plotOptions: {
						bar: {
							columnWidth: optimalColumnWidthPercent + '%',
						},
					},
				});
			}, 1000);
			if (data.value.skills.length) {
				radarData.value = [
					{
						name: 'Kết quả',
						data: data.value.skills.map((skill) => {
							return skill?.skill?.skill_percent;
						}),
					},
				];
				radarChartOptions.value.xaxis.categories =
					data.value.skills.map((skill) => {
						return skill?.skill?.name;
					});
				categories.value = data.value.skills.map((skill) => {
					return skill?.skill?.name;
				});
			}
		}
	} catch (e) {
		console.log(e);
	} finally {
		loading.value = false;
	}
};

function toPreview(result) {
	localStorage.setItem('result', JSON.stringify(result));
	isShow.value = false;
	router.push(
		`/record/student/${
			route.params.id
		}/plan/preview?start_date=${formatDate(
			startDate.value
		)}&end_date=${formatDate(endDate.value)}`
	);
}
</script>
<template>
	<div :class="isShow ? 'side-over--active' : ''">
		<div
			v-if="isShow"
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			@click="isShow = false"
		></div>
		<ResultModal
			v-model:refresh="isShow"
			@close="isShow = false"
			@to-preview="toPreview"
		/>
	</div>
	<div>
		<div class="search-input w-ful flex md-down:flex-col gap-6">
			<div style="width: 200px">
				<div class="input-group__label">Từ ngày</div>
				<div class="input-group">
					<VueDatepicker
						v-model="startDate"
						:enableTimePicker="false"
						:max-date="endDate ? new Date(endDate) : new Date()"
						autoApply
						class="input-date w-full"
						format="dd/MM/yyyy"
						minutesIncrement="30"
						placeholder="Chọn ngày"
						uid="filter-by-date"
					>
						<template #input-icon>
							<svg
								fill="none"
								height="20"
								viewBox="0 0 20 20"
								width="20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									clip-rule="evenodd"
									d="M5 0.625C5 0.279822 4.72018 0 4.375 0C4.02982 0 3.75 0.279822 3.75 0.625V1.25H2.5C1.11929 1.25 0 2.36929 0 3.75V5H20V3.75C20 2.36929 18.8807 1.25 17.5 1.25H16.25V0.625C16.25 0.279822 15.9702 0 15.625 0C15.2798 0 15 0.279822 15 0.625V1.25H5V0.625ZM20 17.5V6.25H0V17.5C0 18.8807 1.11929 20 2.5 20H17.5C18.8807 20 20 18.8807 20 17.5ZM11.875 8.75C11.5298 8.75 11.25 9.02982 11.25 9.375V10.625C11.25 10.9702 11.5298 11.25 11.875 11.25H13.125C13.4702 11.25 13.75 10.9702 13.75 10.625V9.375C13.75 9.02982 13.4702 8.75 13.125 8.75H11.875ZM15.625 8.75C15.2798 8.75 15 9.02982 15 9.375V10.625C15 10.9702 15.2798 11.25 15.625 11.25H16.875C17.2202 11.25 17.5 10.9702 17.5 10.625V9.375C17.5 9.02982 17.2202 8.75 16.875 8.75H15.625ZM2.5 13.125C2.5 12.7798 2.77982 12.5 3.125 12.5H4.375C4.72018 12.5 5 12.7798 5 13.125V14.375C5 14.7202 4.72018 15 4.375 15H3.125C2.77982 15 2.5 14.7202 2.5 14.375V13.125ZM6.875 12.5C6.52982 12.5 6.25 12.7798 6.25 13.125V14.375C6.25 14.7202 6.52982 15 6.875 15H8.125C8.47018 15 8.75 14.7202 8.75 14.375V13.125C8.75 12.7798 8.47018 12.5 8.125 12.5H6.875Z"
									fill="#94A3B8"
									fill-rule="evenodd"
								/>
							</svg>
						</template>
					</VueDatepicker>
				</div>
			</div>
			<div style="width: 200px">
				<div class="input-group__label">Đến ngày</div>
				<div class="input-group">
					<VueDatepicker
						v-model="endDate"
						:disabled="!startDate"
						:enableTimePicker="false"
						:max-date="new Date()"
						:min-date="new Date(startDate)"
						autoApply
						class="input-date w-full"
						format="dd/MM/yyyy"
						minutesIncrement="30"
						placeholder="Chọn ngày"
						uid="filter-by-date"
					>
						<template #input-icon>
							<svg
								fill="none"
								height="20"
								viewBox="0 0 20 20"
								width="20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									clip-rule="evenodd"
									d="M5 0.625C5 0.279822 4.72018 0 4.375 0C4.02982 0 3.75 0.279822 3.75 0.625V1.25H2.5C1.11929 1.25 0 2.36929 0 3.75V5H20V3.75C20 2.36929 18.8807 1.25 17.5 1.25H16.25V0.625C16.25 0.279822 15.9702 0 15.625 0C15.2798 0 15 0.279822 15 0.625V1.25H5V0.625ZM20 17.5V6.25H0V17.5C0 18.8807 1.11929 20 2.5 20H17.5C18.8807 20 20 18.8807 20 17.5ZM11.875 8.75C11.5298 8.75 11.25 9.02982 11.25 9.375V10.625C11.25 10.9702 11.5298 11.25 11.875 11.25H13.125C13.4702 11.25 13.75 10.9702 13.75 10.625V9.375C13.75 9.02982 13.4702 8.75 13.125 8.75H11.875ZM15.625 8.75C15.2798 8.75 15 9.02982 15 9.375V10.625C15 10.9702 15.2798 11.25 15.625 11.25H16.875C17.2202 11.25 17.5 10.9702 17.5 10.625V9.375C17.5 9.02982 17.2202 8.75 16.875 8.75H15.625ZM2.5 13.125C2.5 12.7798 2.77982 12.5 3.125 12.5H4.375C4.72018 12.5 5 12.7798 5 13.125V14.375C5 14.7202 4.72018 15 4.375 15H3.125C2.77982 15 2.5 14.7202 2.5 14.375V13.125ZM6.875 12.5C6.52982 12.5 6.25 12.7798 6.25 13.125V14.375C6.25 14.7202 6.52982 15 6.875 15H8.125C8.47018 15 8.75 14.7202 8.75 14.375V13.125C8.75 12.7798 8.47018 12.5 8.125 12.5H6.875Z"
									fill="#94A3B8"
									fill-rule="evenodd"
								/>
							</svg>
						</template>
					</VueDatepicker>
				</div>
			</div>
		</div>
		<div v-if="loading" class="mt-10 flex items-center justify-center h-28">
			<Loading />
		</div>
		<div v-else>
			<div v-if="startDate && endDate && !empty">
				<div class="relative mt-12">
					<div
						aria-hidden="true"
						class="absolute inset-0 flex items-center"
					>
						<div class="w-full border-t border-neutral-300" />
					</div>
					<div class="relative flex items-center justify-center">
						<div
							class="
								px-2.5
								py-1
								bg-body
								text-neutral-600
								font-semibold
							"
						>
							Thống kê chung
						</div>
					</div>
				</div>
				<div class="mt-10">
					<div class="p-6 bg-white shadow-sm rounded-2xl">
						<div
							class="
								w-full
								flex
								items-center
								justify-between
								mb-6
							"
						>
							<div class="font-semibold text-neutral-600">
								Thống kê chung
							</div>
							<div>
								<Menu as="div" class="ml-3 relative">
									<MenuButton
										class="
											rounded-full
											flex
											text-sm
											focus:outline-none
											focus:ring-2
											focus:ring-offset-2
											focus:ring-indigo-500
										"
									>
										<span class="sr-only"
											>Open user menu</span
										>
										<svg
											fill="none"
											height="20"
											viewBox="0 0 20 20"
											width="20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												clip-rule="evenodd"
												d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
												fill="#94A3B8"
												fill-rule="evenodd"
											/>
										</svg>
									</MenuButton>
									<transition
										enter-active-class="transition ease-out duration-200"
										enter-from-class="transform opacity-0 scale-95"
										enter-to-class="transform opacity-100 scale-100"
										leave-active-class="transition ease-in duration-75"
										leave-from-class="transform opacity-100 scale-100"
										leave-to-class="transform opacity-0 scale-95"
									>
										<MenuItems
											class="
												origin-top-right
												absolute
												right-0
												mt-2
												w-60
												rounded-md
												py-1
												bg-white
												focus:outline-none
												z-10
												shadow-sm
												border border-yellow-100
											"
										>
											<MenuItem
												v-slot="{ active }"
												@click="isShow = true"
											>
												<div
													:class="active ? '' : ''"
													class="
														p-2
														px-4
														bg-white
														z-10
														text-gray-600
														hover:bg-neutral-50
														flex
														items-center
														gap-4
														cursor-pointer
													"
												>
													<svg
														fill="none"
														height="16"
														viewBox="0 0 16 16"
														width="16"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M13.9759 2.73814L9.31765 15.7438C9.26208 15.8975 9.1162 15.9999 8.95276 16H8.82854C8.67608 16 8.53718 15.9124 8.47141 15.7748L6.63918 11.8926C6.36938 11.3202 6.4738 10.6414 6.90314 10.1766L9.23225 7.62981C9.3752 7.47984 9.3752 7.24403 9.23225 7.09406L8.93723 6.799C8.78728 6.65604 8.55149 6.65604 8.40154 6.799L5.85504 9.12837C5.39031 9.55775 4.71155 9.66219 4.13926 9.39236L0.257408 7.55993C0.107709 7.50356 0.00662856 7.36264 0.00120576 7.20276V7.07853C-0.0125429 6.90383 0.0924328 6.74158 0.257408 6.68254L13.2616 2.0238C13.4014 1.97246 13.5583 2.00564 13.6653 2.10921L13.8672 2.31109C13.9885 2.41709 14.0318 2.58703 13.9759 2.73814Z"
															fill="#94A3B8"
														/>
													</svg>

													<span class="mt-0.5"
														>Gửi tới phụ huynh</span
													>
												</div>
											</MenuItem>
										</MenuItems>
									</transition>
								</Menu>
							</div>
						</div>
						<div class="flex flex-nowrap w-full md-down:flex-col">
							<div
								class="
									p-6
									border
									rounded-l-lg
									border-neutral-200
									w-full
									md-down:rounded-t md-down:rounded-b-none
								"
							>
								<div
									class="
										text-33
										font-bold
										text-neutral-600
										mb-6
										flex flex-nowrap
										items-center
									"
								>
									<img
										alt=""
										class="mr-2"
										height="32"
										src="@/assets/images/icons/abc.png"
										style="height: 32px"
										width="32"
									/>
									{{
										Number(statistics?.skill_count || 0) +
										'/' +
										Number(
											data?.stats?.total_skill_count || 0
										)
									}}
								</div>
								<div class="flex flex-nowrap items-center">
									<span class="text-neutral-500 text-sm"
										>Lĩnh vực đã học
									</span>
								</div>
							</div>
							<div
								class="
									p-6
									border-t border-b border-neutral-200
									w-full
									md-down:border-l
									md-down:border-r
									md-down:border-b-0
								"
							>
								<div
									class="
										text-33
										font-bold
										text-neutral-600
										mb-6
										flex flex-nowrap
										items-center
									"
								>
									<img
										alt=""
										class="mr-2"
										height="32"
										src="@/assets/images/icons/light.png"
										style="height: 32px"
										width="32"
									/>
									{{
										Number(statistics?.goal_count || 0) +
										'/' +
										Number(
											data?.stats?.total_goal_count || 0
										)
									}}
								</div>
								<div class="flex flex-nowrap items-center">
									<span class="text-neutral-500 text-sm"
										>Mục tiêu đã học
									</span>
								</div>
							</div>
							<div
								class="
									p-6
									border-l
									border-t
									border-b
									border-neutral-200
									w-full
									md-down:border-r md-down:border-b-0
								"
							>
								<div
									class="
										text-33
										font-bold
										text-neutral-600
										mb-6
										flex flex-nowrap
										items-center
									"
								>
									<img
										alt=""
										class="mr-2"
										height="32"
										src="@/assets/images/icons/light.png"
										style="height: 32px"
										width="32"
									/>
									{{ statistics?.completed || 0 }}
								</div>
								<div class="flex flex-nowrap items-center">
									<span class="text-neutral-500 text-sm"
										>Mục tiêu đã hoàn thành
									</span>
								</div>
							</div>
							<div
								class="
									p-6
									border-l
									border-t
									border-b
									border-neutral-200
									w-full
									md-down:border-r md-down:border-b-0
								"
							>
								<div
									class="
										text-33
										font-bold
										text-neutral-600
										mb-6
										flex flex-nowrap
										items-center
									"
								>
									<img
										alt=""
										class="mr-2"
										height="32"
										src="@/assets/images/icons/light.png"
										style="height: 32px"
										width="32"
									/>
									{{ statistics?.continued || 0 }}
								</div>
								<div class="flex flex-nowrap items-center">
									<span class="text-neutral-500 text-sm"
										>Mục tiêu sẽ tiếp tục
									</span>
								</div>
							</div>
							<div
								class="
									p-6
									border
									rounded-r-lg
									border-neutral-200
									w-full
									md-down:rounded-b md-down:rounded-t-none
								"
							>
								<div
									class="
										text-33
										font-bold
										text-neutral-600
										mb-6
										flex flex-nowrap
										items-center
									"
								>
									<img
										alt=""
										class="mr-2"
										height="32"
										src="@/assets/images/icons/light.png"
										style="height: 32px"
										width="32"
									/>
									{{ statistics?.stopped || 0 }}
								</div>
								<div class="flex flex-nowrap items-center">
									<span class="text-neutral-500 text-sm"
										>Mục tiêu sẽ tạm dừng</span
									>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-6 flex items-stretch gap-6 xl-down:flex-col">
					<div
						class="
							bg-white
							shadow-sm
							rounded-2xl
							overflow-hidden
							w-1/3
							xl-down:w-full
						"
					>
						<div class="font-semibold text-neutral-500 p-6">
							Biều đồ cảm xúc
						</div>
						<div
							class="
								border-t border-orange-50
								flex
								justify-center
								items-center
								p-6
								flex-col
							"
						>
							<div
								class="
									relative
									w-full
									md-down:w-full
									flex
									items-center
									justify-center
								"
							>
								<apexchart
									:options="chartOptions3"
									:series="series3"
									height="220"
									type="donut"
								></apexchart>
								<div
									class="
										text-center
										absolute
										top-1/2
										left-1/2
										transform
									"
									style="transform: translate(-50%, -50%)"
								>
									<div class="text-neutral-500">Cảm xúc</div>
								</div>
							</div>
							<div class="w-full">
								<div
									v-if="series3[0] > 0"
									class="
										flex flex-nowrap
										items-center
										justify-between
										w-full
									"
								>
									<div class="flex flex-nowrap items-center">
										<img
											alt=""
											height="24"
											src="@/assets/images/emoji/happy.png"
											width="24"
										/>
										<div
											class="
												h-2.5
												w-2.5
												rounded-full
												ml-2
											"
											style="background: #9edf93"
										></div>
										<div
											class="
												ml-2
												text-base text-neutral-500
											"
										>
											Vui vẻ
										</div>
									</div>
									<div
										class="
											text-base text-neutral-600
											font-bold
										"
									>
										{{ series3[0] }}
										%
									</div>
								</div>
								<div
									v-if="series3[1] > 0"
									class="
										flex flex-nowrap
										items-center
										justify-between
										w-full
										mt-2.5
									"
								>
									<div class="flex flex-nowrap items-center">
										<img
											alt=""
											height="24"
											src="@/assets/images/emoji/5565d66a50b44df9ae37a955bdfb3757.png"
											width="24"
										/>
										<div
											class="
												h-2.5
												w-2.5
												rounded-full
												ml-2
											"
											style="background: #d0db54"
										></div>
										<div
											class="
												ml-2
												text-base text-neutral-500
											"
										>
											Bình thường
										</div>
									</div>
									<div
										class="
											text-base text-neutral-600
											font-bold
										"
									>
										{{ series3[1] }}
										%
									</div>
								</div>
								<div
									v-if="series3[2] > 0"
									class="
										flex flex-nowrap
										items-center
										justify-between
										w-full
										mt-2.5
									"
								>
									<div class="flex flex-nowrap items-center">
										<img
											alt=""
											height="24"
											src="@/assets/images/emoji/cf1f643bc1244f5e8e37767bc946d607.png"
											width="24"
										/>
										<div
											class="
												h-2.5
												w-2.5
												rounded-full
												ml-2
											"
											style="background: #ffe68c"
										></div>
										<div
											class="
												ml-2
												text-base text-neutral-500
											"
										>
											Khó chịu
										</div>
									</div>
									<div
										class="
											text-base text-neutral-600
											font-bold
										"
									>
										{{ series3[2] }}
										%
									</div>
								</div>
								<div
									v-if="series3[3] > 0"
									class="
										flex flex-nowrap
										items-center
										justify-between
										w-full
										mt-2.5
									"
								>
									<div class="flex flex-nowrap items-center">
										<img
											alt=""
											height="24"
											src="@/assets/images/emoji/5ede936757cd4fbdb6247bad22bf454d.png"
											width="24"
										/>
										<div
											class="
												h-2.5
												w-2.5
												rounded-full
												ml-2
											"
											style="background: #ff9d6d"
										></div>
										<div
											class="
												ml-2
												text-base text-neutral-500
											"
										>
											Tức giận
										</div>
									</div>
									<div
										class="
											text-base text-neutral-600
											font-bold
										"
									>
										{{ series3[3] }}
										%
									</div>
								</div>
								<div
									v-if="series3[4] > 0"
									class="
										flex flex-nowrap
										items-center
										justify-between
										w-full
										mt-2.5
									"
								>
									<div class="flex flex-nowrap items-center">
										<img
											alt=""
											height="24"
											src="@/assets/images/emoji/0b356bb507d64b9489e2fbced131f795.png"
											width="24"
										/>
										<div
											class="
												h-2.5
												w-2.5
												rounded-full
												ml-2
											"
											style="background: #ff6967"
										></div>
										<div
											class="
												ml-2
												text-base text-neutral-500
											"
										>
											Bùng nổ
										</div>
									</div>
									<div
										class="
											text-base text-neutral-600
											font-bold
										"
									>
										{{ series3[4] }}
										%
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="
							bg-white
							shadow-sm
							rounded-2xl
							overflow-hidden
							w-1/3
							xl-down:w-full xl-down:mt-6
						"
					>
						<div class="font-semibold text-neutral-500 p-6">
							Biểu đồ kết quả các mục tiêu
						</div>
						<div
							class="
								flex flex-nowrap
								p-6
								border-t border-orange-50
								items-center
								justify-center
								flex-col
							"
						>
							<div class="relative w-fit md-down:w-full">
								<apexchart
									:options="chartOptions"
									:series="series"
									height="220"
									type="donut"
								></apexchart>
								<div
									class="
										text-center
										absolute
										top-1/2
										left-1/2
										transform
									"
									style="transform: translate(-50%, -50%)"
								>
									<div
										class="
											text-4xl
											font-bold
											text-neutral-600
										"
									>
										{{ statistics?.goal_count }}
									</div>
									<div class="text-neutral-500">Mục tiêu</div>
								</div>
							</div>
							<div
								class="
									flex flex-col
									w-full
									justify-center
									md-down:mt-4
								"
							>
								<div
									v-if="result[0]?.count > 0"
									class="
										flex flex-nowrap
										items-center
										mb-4
										justify-between
									"
								>
									<div
										class="
											text-sm
											font-semibold
											text-neutral-500
											w-14
											blue
										"
									>
										{{ result[0]?.count }}
										M.T
									</div>
									<span
										class="
											flex
											items-center
											justify-center
											py-0.5
											rounded-full
											text-sm text-white
											font-semibold
											whitespace-nowrap
											w-32
											shadow-input
										"
										style="background: #2aa797"
									>
										{{ result[0]?.result }}
									</span>
								</div>
								<div
									v-if="result[1]?.count > 0"
									class="
										flex flex-nowrap
										items-center
										mb-4
										justify-between
									"
								>
									<div
										class="
											text-sm text-neutral-500
											w-14
											font-semibold
										"
									>
										{{ result[1]?.count }}
										M.T
									</div>
									<span
										class="
											flex
											items-center
											justify-center
											py-0.5
											rounded-full
											text-sm text-white
											font-semibold
											w-32
											whitespace-nowrap
											shadow-input
										"
										style="background: #65a30d"
									>
										{{ result[1]?.result }}
									</span>
								</div>
								<div
									v-if="result[2]?.count > 0"
									class="
										flex flex-nowrap
										items-center
										mb-4
										justify-between
									"
								>
									<div
										class="
											text-sm
											font-semibold
											text-neutral-500
											w-14
										"
									>
										{{ result[2]?.count }}
										M.T
									</div>
									<span
										class="
											flex
											items-center
											justify-center
											py-0.5
											rounded-full
											text-sm text-white
											font-semibold
											w-32
											whitespace-nowrap
											shadow-input
										"
										style="background: #f59e0b"
									>
										{{ result[2]?.result }}
									</span>
								</div>
								<div
									v-if="result[3]?.count > 0"
									class="
										flex flex-nowrap
										items-center
										mb-4
										justify-between
									"
								>
									<div
										class="
											text-sm
											font-semibold
											text-neutral-500
											w-14
											blue
										"
									>
										{{ result[3]?.count }}
										M.T
									</div>
									<span
										class="
											flex
											items-center
											justify-center
											px-3
											py-0.5
											rounded-full
											text-sm text-white
											font-semibold
											w-32
											whitespace-nowrap
											shadow-input
										"
										style="background: #f87171"
									>
										{{ result[3]?.result }}
									</span>
								</div>
								<div
									v-if="
										result[4]?.count > 0 ||
										result[5]?.count > 0 ||
										result[6]?.count > 0
									"
									class="
										flex flex-nowrap
										items-center
										mb-4
										justify-between
									"
								>
									<div
										class="
											text-sm
											font-semibold
											text-neutral-500
											w-14
											blue
										"
									>
										{{
											result[4]?.count +
											result[5]?.count +
											result[6]?.count
										}}
										M.T
									</div>
									<span
										class="
											flex
											items-center
											justify-center
											px-3
											py-0.5
											rounded-full
											text-sm text-white
											font-semibold
											w-32
											whitespace-nowrap
											shadow-input
										"
										style="background: #9ca3af"
									>
										X
									</span>
								</div>
							</div>
						</div>
					</div>
					<div
						class="
							bg-white
							shadow-sm
							rounded-2xl
							overflow-hidden
							w-1/3
							xl-down:w-full xl-down:mt-6
						"
					>
						<div class="font-semibold text-neutral-500 p-6">
							Chuyên cần
						</div>
						<div
							class="
								border-t border-orange-50
								p-6
								flex
								items-center
								justify-center
								flex-col
							"
						>
							<div
								class="text-neutral-600 font-semibold mb-5"
								style="font-size: 72px"
							>
								{{ data?.stats?.attendance }}
							</div>
							<div class="text-neutral-500 mb-5 text-center">
								Số ngày học
							</div>
						</div>
					</div>
				</div>
				<div class="mt-6">
					<div
						class="
							bg-white
							shadow-sm
							rounded-2xl
							overflow-hidden
							mb-10
							pb-5
						"
					>
						<div class="font-semibold text-neutral-500 p-6">
							Mối tương quan giữa kết quả, cảm xúc và sự tham gia
							của trẻ từ
							{{ formatDate(startDate, true) }} tới
							{{ formatDate(endDate, true) }}
						</div>
						<div
							class="
								p-6
								border-t border-orange-50
								md-down:px-0
								overflow-x-auto
								w-full
							"
						>
							<apexchart
								ref="mixChart"
								:options="mixChartOptions"
								:series="emotion_cooperation_result_by_day_data"
								:style="{
									minWidth: `${
										emotion_cooperation_result_by_day_emotion?.length *
										150
									}px`,
								}"
								height="600"
								style="width: 100%"
								width="100%"
							></apexchart>
						</div>
					</div>
				</div>
				<div
					v-if="radarData.length && radarData[0]?.data.length >= 3"
					class="mt-12"
				>
					<div
						class="
							bg-white
							shadow-sm
							overflow-hidden
							rounded-2xl
							mb-10
							pb-5
						"
					>
						<div class="font-semibold text-neutral-500 p-6">
							Biểu đồ mối tương quan giữa kết quả các lĩnh vực từ
							{{ formatDate(startDate, true) }} tới
							{{ formatDate(endDate, true) }}
						</div>
						<div
							class="
								p-6
								border-t border-orange-50
								md-down:px-0
								overflow-x-auto
								w-full
							"
						>
							<apexchart
								:options="radarChartOptions"
								:series="radarData"
								height="700"
								type="radar"
							></apexchart>
							<div
								class="
									py-4
									flex flex-wrap
									gap-6
									justify-center
									items-center
								"
							>
								<div
									v-for="(c, index) in categories"
									:key="c"
									class="flex items-center flex-nowrap gap-2"
								>
									<div
										class="
											font-bold
											text-lf text-neutral-700
										"
									>
										{{ index + 1 }}: {{ c }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="relative mt-10">
					<div
						aria-hidden="true"
						class="absolute inset-0 flex items-center"
					>
						<div class="w-full border-t border-neutral-300" />
					</div>
					<div class="relative flex items-center justify-center">
						<div
							class="
								px-2.5
								py-1
								bg-body
								text-neutral-600
								font-semibold
							"
						>
							Kết quả mục tiêu đã học
						</div>
					</div>
				</div>
				<div
					v-for="(s, index) in assessment_results"
					:key="index"
					class="mt-10"
				>
					<div class="font-semibold text-lg text-neutral-600 mb-2">
						{{ index + 1 }}. {{ s.skill?.name }}
					</div>
					<div
						class="
							shadow-sm
							sm:rounded-2xl
							overflow-hidden
							md-down:hidden
						"
					>
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									overflow-hidden
									sm:rounded-t-2xl
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												class="
													px-4
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
													w-16
												"
												scope="col"
											>
												STT
											</th>
											<th
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
												scope="col"
											>
												Mục Tiêu
											</th>
											<th
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
												scope="col"
												style="width: 80px"
											>
												Kết quả
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(i, gIndex) in s.goals"
											:key="gIndex"
											:class="
												gIndex % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
											class="
												cursor-pointer
												text-neutral-700
												hover:bg-orange-50
											"
										>
											<td
												class="
													p-4
													whitespace-nowrap
													text-neutral-700 text-center
												"
											>
												{{ gIndex + 1 }}
											</td>
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div>
													<div
														class="
															whitespace-normal
														"
													>
														{{
															i.name ||
															i.goal_name
														}}
													</div>
												</div>
												<div
													class="
														flex
														items-center
														gap-2
														mt-2
													"
												>
													<div v-if="g?.has_media">
														<img
															alt=""
															src="@/assets/images/icons/has_media.png"
														/>
													</div>
													<div
														v-if="
															i.number_of_assessment >
															0
														"
														class="
															text-neutral-400
															text-xs
															font-semibold
														"
													>
														ĐÃ HỌC
														{{
															i.number_of_assessment
														}}
														NGÀY
													</div>
													<div v-if="i?.completed_at">
														<img
															alt=""
															src="@/assets/images/icons/has_taught.png"
														/>
													</div>
												</div>

												<div
													v-if="
														i.parent
															?.sub_goal_count > 0
													"
													class="
														goal-block
														flex
														gap-3
														mt-2
													"
												>
													<img
														class="w-3 h-3 mt-1"
														src="@/assets/images/icons/goal-arrow.png"
														alt=""
													/>
													<div>
														<div
															class="
																text-neutral-700
																whitespace-normal
															"
														>
															{{ i.parent?.name }}
														</div>
														<div
															class="
																text-neutral-400
																text-xs
																font-semibold
															"
														>
															{{
																i.parent
																	?.sub_goal_count
															}}
															MỤC TIÊU
														</div>
													</div>
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div>
													<div
														v-if="
															i?.complete_percent
														"
														:style="{
															background:
																$filters.tagResult(
																	i?.complete_percent
																)?.background,
															color: $filters.tagResult(
																i?.complete_percent
															)?.color,
														}"
														class="															
															py-0.5
															px-2.5
															rounded-full
															text-neutral-700
															h-fit
															w-fit
															font-semibold
															text-sm
															shadow-input
														"
													>
														Đạt
														{{
															i?.complete_percent ||
															0
														}}%
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="mt-10 flex items-center flex-col justify-center">
				<img
					alt=""
					height="250"
					src="@/assets/images/illustrations/not_found.png"
					width="250"
				/>
				<div
					class="text-neutral-500 text-lg text-center"
					style="max-width: 700px"
				>
					<span v-if="!startDate || !endDate"
						>Vui lòng chọn khoảng thời gian cần xem thống kê kết quả
						can thiệp</span
					>
					<span v-else-if="empty"
						>Học sinh chưa có Kết quả can thiệp trong giai đoạn
						này</span
					>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
@import '../../../../../assets/styles/responsive';

.is-MT-show {
	width: 100vw;
	transform: translateX(0);
	transition: all 0.4s ease;

	@include media-breakpoint-up(lg) {
		width: 400px;
	}
}

.is-MT-hide {
	transform: translateX(450px);
	transition: all 0.4s ease;
}

.transition {
	transition: all 0.4s ease;
}

.content-report-is-show {
	@include media-breakpoint-up(lg) {
		padding-right: 448px;
	}
}

.content-report-is-hide {
	@include media-breakpoint-up(lg) {
		padding-right: 40px;
	}
}
</style>
