<template>
	<div
		class="
			fixed
			inset-y-0
			right-0
			pl-10
			flex
			side-over
			z-50
			overflow-y-auto
		"
		style="width: 720px"
	>
		<div
			class="h-full w-full flex flex-col bg-white shadow-xl"
			style="min-height: 100vh; height: fit-content"
		>
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M13.6308 5.68775L15.8838 3.43471C16.7496 2.56893 17.1825 2.13604 17.6895 1.99526C18.0396 1.89806 18.4095 1.89806 18.7596 1.99526C19.2667 2.13604 19.6996 2.56893 20.5653 3.43471C21.4311 4.3005 21.864 4.73339 22.0048 5.24044C22.102 5.59052 22.102 5.96046 22.0048 6.31054C21.864 6.8176 21.4311 7.25049 20.5653 8.11627L18.3384 10.3432C16.3859 9.2234 14.7651 7.61489 13.6308 5.68775ZM12.1764 7.14212L3.8659 15.4526C3.44084 15.8777 3.22831 16.0902 3.08857 16.3513C2.94884 16.6124 2.88989 16.9071 2.772 17.4966L1.98622 21.4255C1.91969 21.7581 1.88643 21.9244 1.98104 22.019C2.07564 22.1136 2.24194 22.0804 2.57452 22.0139L2.57455 22.0139L2.57456 22.0138L6.50349 21.2281L6.50351 21.2281C7.09295 21.1102 7.38767 21.0512 7.64876 20.9115C7.90986 20.7718 8.12239 20.5592 8.54743 20.1342L8.54745 20.1342L16.8803 11.8013C14.9786 10.6172 13.3722 9.02191 12.1764 7.14212Z"
								fill="#94A3B8"
							/>
						</svg>

						<div class="text-2xl font-bold text-neutral-700 ml-2">
							Chỉnh sửa lĩnh vực
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div class="mt-6 relative flex-1 px-4 sm:px-6">
				<div class="flex items-end w-full flex-col" v-if="!isLoading">
					<div
						class="w-full"
						v-for="skill in skills.filter(
							(s) => !deletedSkill.includes(s)
						)"
						:key="skill.id"
					>
						<div class="input-group flex items-center mb-4">
							<input type="text" v-model="skill.name" />
							<DaIcon
								v-if="!skill.assessment_count && skill.can_delete"
								@click="addToDelete(skill)"
								name="das--delete"
								class="
									h-5
									w-5
									text-blue-600
									hover:text-blue-900
									active:text-blue-500
									cursor-pointer
									absolute
									right-3
								"
							/>
						</div>
					</div>
					<button
						v-if="false"
						type="button"
						class="
							button-p
							text-center
							bg-none
							shadow-none
							flex
							items-center
							justify-center
							btn--text
							w-fit
						"
					>
						Thêm lĩnh vực
					</button>
				</div>
				<div v-else><Loading /></div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-100">
				<div class="flex justify-end">
					<div>
						<button
							type="button"
							class="button-p text-center w-32"
							@click="updateSkills"
						>
							Xác nhận
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DaIcon from '@/components/elements/daIcon/DaIcon';
import { $api } from '@/services';
import Loading from '@/components/common/Loading';
export default {
	name: 'UpdateSkill',

	components: { Loading, DaIcon },

	props: {
		isOpen: {
			type: Boolean,
			required: true,
		},
	},

	watch: {
		isOpen: {
			immediate: true,
			handler(value) {
				if (value) {
					this.fetchSkills();
				} else {
					this.skills = [];
					this.error = '';
					this.deletedSkill = [];
				}
			},
		},
	},

	data() {
		return {
			skills: [],
			error: '',
			deletedSkill: [],
			isLoading: true,
		};
	},

	methods: {
		async fetchSkills() {
			try {
				this.isLoading = true;
				const {
					data: { data },
				} = await $api.org.fetchSkills();
				this.skills = data.filter((s) => s.is_custom) || [];
			} catch (err) {
				console.log(err);
			} finally {
				this.isLoading = false;
			}
		},

		async addToDelete(skill) {
			this.deletedSkill.push(skill);
		},

		async updateSkills() {
			try {
				let isUpdated = false;
				if (this.deletedSkill.length) {
					await $api.org.removeSkillsBatch({
						ids: this.deletedSkill.map((s) => s.id),
					});
					isUpdated = true;
				} else if (
					this.skills.filter((s) => !this.deletedSkill.includes(s))
						?.length
				) {
					await $api.org.updateSkillsBatch({
						batch_update_skills: this.skills.filter(
							(s) => !this.deletedSkill.includes(s)
						),
					});
					isUpdated = true;
				}
				if (isUpdated) {
					this.$store.dispatch('setAlertMessage', {
						message: 'Cập nhật lĩnh vực thành công',
					});
				}
				isUpdated = false;
				this.$emit('refetch');
			} catch (err) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						err?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},
	},
};
</script>
