/* eslint-disable */
import { $http } from '@/plugins/axios';
import { AxiosResponse } from 'axios';

export const orgService = {
	getOrg: (): Promise<AxiosResponse> => {
		return $http.get('v1/org_admin/org');
	},
	updateOrg: (data: any): Promise<AxiosResponse> => {
		return $http.put('/v1/org_admin/org', data);
	},
	uploadProfilePicture(payload: any) {
		return $http.put(`/v1/org_admin/org/upload_profile_photo`, payload, {
			headers: {
				'content-type': 'multipart/form-data;',
			},
		});
	},

	mediaCurrent: (params: any): Promise<AxiosResponse> => {
		return $http.get('v1/org_admin/video_storage/current_month', {
			params,
		});
	},

	mediaByMonth: (params: any): Promise<AxiosResponse> => {
		return $http.get('v1/org_admin/video_storage/by_month', {
			params,
		});
	},

	fetchSkills: (params?: any): Promise<AxiosResponse> => {
		return $http.get('v1/staff/skills', {
			params,
		});
	},

	updateSkills: (params: any): Promise<AxiosResponse> => {
		return $http.put('v1/org_admin/org_skills', params);
	},

	updateSkillsBatch: (params: any): Promise<AxiosResponse> => {
		return $http.put('v1/org_admin/skills/batch_update', params);
	},

	removeSkillsBatch: (params: any): Promise<AxiosResponse> => {
		return $http.delete('v1/org_admin/skills/batch_delete', {
			params,
		});
	},
	listOnlyFolder: (): Promise<AxiosResponse> => {
		return $http.get(`v1/org_admin/reference_document_folders/all`);
	},
	listDocumentsFolder: (params?: any): Promise<AxiosResponse> => {
		return $http.get(`v1/org_admin/reference_document_folders/`, {
			params,
		});
	},
	listLucaDocumentsFolder: (params?: any): Promise<AxiosResponse> => {
		return $http.get(`v1/staff/references/index_by_group`, {
			params,
		});
	},
	createDocumentsFolder: (data: any): Promise<AxiosResponse> => {
		return $http.post(`v1/org_admin/reference_document_folders/`, data);
	},
	changeFolderName: (id: string, data: any): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/reference_document_folders/${id}`, data);
	},
	deleteFolderDocuments: (id: string): Promise<AxiosResponse> => {
		return $http.delete(`v1/org_admin/reference_document_folders/${id}`);
	},
	documents: (params: any): Promise<AxiosResponse> => {
		return $http.get('v1/org_admin/reference_documents', {
			params,
		});
	},

	documentDetail: (id: string): Promise<AxiosResponse> => {
		return $http.get(`v1/org_admin/reference_documents/${id}`);
	},

	lucaDocumentDetail: (
		id: string,
		categoryId: string
	): Promise<AxiosResponse> => {
		return $http.get(
			`v1/staff/reference_categories/${categoryId}/references/${id}`
		);
	},

	deleteDocuments: (id: string): Promise<AxiosResponse> => {
		return $http.delete(`v1/org_admin/reference_documents/${id}`);
	},

	createDocuments: (data: any): Promise<AxiosResponse> => {
		return $http.post(`v1/org_admin/reference_documents/`, data);
	},

	updateDocuments: (id: string, data: any): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/reference_documents/${id}`, data);
	},

	getStudentAssessment() {
		return $http.get(`v1/org_admin/students_has_assignment`);
	},

	getPeriodicAssessment() {
		return $http.get(`v1/org_admin/students_with_periodic_assessment`);
	},

	updateStudentPeriodicAssessment(payload: any) {
		return $http.put(
			`v1/org_admin/periodic_assessments/batch_update`,
			payload
		);
	},
	fetchActiveAssistanceLevel() {
		return $http.get('v1/org_admin/org_assistance_levels');
	},
	fetchAssistanceLevel() {
		return $http.get('v1/org_admin/assistance_levels');
	},
	updateAssistanceLevel: (params: any): Promise<AxiosResponse> => {
		return $http.post('v1/org_admin/org_assistance_levels', params);
	},
	staffFetchAssistanceLevel() {
		return $http.get('v1/staff/org_assistance_levels');
	},
	getTuitionFee: (params?: any): Promise<AxiosResponse> => {
		return $http.get('v1/org_admin/tuition_unit_prices', {
			params,
		});
	},
	getStudentTuitionFee: (id?: string, params?: any): Promise<AxiosResponse> => {
		return $http.get(`v1/accountant/tuition_templates/${id}/student_tuition_from_templates/by_month`, {
			params,
		});
	},


	createTuitionFee: (data: any): Promise<AxiosResponse> => {
		return $http.post('v1/org_admin/tuition_unit_prices', data);
	},

	updateTuitionFee: (data: any): Promise<AxiosResponse> => {
		return $http.put('v1/org_admin/tuition_unit_prices', data);
	},

	updateTuitionFeeForStudent: (
		studentId: number,
		id: number,
		data: any
	): Promise<AxiosResponse> => {
		return id
			? $http.put(
				`v1/org_admin/students/${studentId}/student_tuition_unit_prices`,
				data
			)
			: $http.post(
				`v1/org_admin/students/${studentId}/student_tuition_unit_prices`,
				data
			);
	},

	getForms: (params?: any): Promise<AxiosResponse> => {
		return $http.get('v1/accountant/tuition_templates', {
			params,
		});
	},

	deleteForm: (id: string): Promise<AxiosResponse> => {
		return $http.delete(`v1/accountant/tuition_templates/${id}`);
	},

	createForm: (data: any): Promise<AxiosResponse> => {
		return $http.post('v1/accountant/tuition_templates', data);
	},

	updateForm: (id: string, data: any): Promise<AxiosResponse> => {
		return $http.put(`v1/accountant/tuition_templates/${id}`, data);
	},
};
