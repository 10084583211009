
import { Options, Vue } from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import Checkbox from '@/components/elements/checkbox/Checkbox.vue';


@Options({
	name: 'DeleteListGoal',
	components: { Checkbox },
})
export default class DeleteListGoal extends Vue {
    @Prop() goalDelete!: any[];
	@PropSync('isNoRepeat') noRepeat?: boolean;

	close() {
		this.$emit('close');
	}

	submit() {
		this.$emit('submit');
	}

}
