
import { $api } from '@/services';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import TabTarget from '@/views/report/features/monthlyReport/tabTarget/TabTarget.vue';
import TargetDetail from '@/views/report/features/monthlyReport/targetDetail/TargetDetail.vue';
import { useStore } from 'vuex';
import { _MODAL_TYPE, _ROUTER_NAME } from '@/enums';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import ConfirmModal from '@/views/report/features/monthlyReport/features/confirmModal/ConfirmModal.vue';
import { _GET_DATE_2 } from '@/helper/formatDate';
import CopyModal from '@/views/report/features/copyModal/CopyModal.vue';
import ExitModal from '@/views/report/features/monthlyReport/features/exitConfirm/ExitModal.vue';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import MakeReportStep3 from '@/components/modules/monthlyReport/MakeReportStep3.vue';
import ErrorModal from '@/views/report/features/monthlyReport/features/errorModal/ErrorModal.vue';
import downloadCSVPlanReport from '@/mixins/downloadCSV.vue';
import ConfirmSendMonthlyReport from '@/views/report/features/monthlyReport/features/ConfirmSendMonthlyReport/ConfirmSendMonthlyReport.vue';
import Loading from '@/components/common/Loading.vue';
import { _MONTH_DIFF } from '@/helper/monthDiff';
import dayjs from 'dayjs';

export default defineComponent({
	name: 'MonthlyReport',
	components: {
		Loading,
		ConfirmSendMonthlyReport,
		ErrorModal,
		MakeReportStep3,
		DaIcon,
		ExitModal,
		CopyModal,
		ConfirmModal,
		DaModalFrame,
		TargetDetail,
		TabTarget,
	},
	setup() {
		const route = useRoute();
		const router = useRouter();
		const store = useStore();
		const step = ref(1);

		const id = ref(route.params.id as string);
		const monthly_start = ref(route.query.month_start as string);
		const goalSelectedId = ref(route.query.goal as string);
		const goalSelected = ref(null as any);
		const goalSelected__next = ref(null as any);
		const goalIndex = ref(0 as number);
		const skillIndex = ref(0 as number);

		const report = ref(null as any);
		const skills = ref(null as any);
		const skillsShow = ref(null as any);
		const all_goals_reported = ref(false);
		const alertShow = ref(true);
		const isLoading = ref(true);
		const listVideo = ref([]);
		const errorData = ref({
			error: '',
			reportError: [],
		});
		const tabs = ref([
			{
				name: 'Nội dung báo cáo',
				value: 0,
			},
			{
				name: 'Video từ phụ huynh',
				value: 1,
			},
		]);
		const tabSelected = ref(0);
		const is_old_data = computed(() => route.query.is_old_data);

		watch(
			goalSelected,
			(value) => {
				if (value) {
					goalSelectedId.value = value.goal_id || value.id;
					if (!is_old_data.value) {
						fetchGoalAssignmentDetail();
					}
					isEditTargetDetail.value = false;
					const top =
						document.getElementById('target-top')?.offsetTop;
					if (top) {
						window.scrollTo(0, top - 100);
					}
				}
			},
			{ immediate: true, deep: true }
		);
		const editQuery = route.query.action === 'edit' ? true : '';
		let editReport = ref(editQuery);
		watch(
			editReport,
			(value) => {
				if (value) {
					const queryRoute = Object.assign({}, route.query);
					queryRoute.action = 'edit';
					router.push({
						query: queryRoute,
					});
				}
			},
			{ immediate: true }
		);

		watch(route, (value: any) => {
			editReport.value = value.query.action === 'edit' ? true : '';
		});

		function edit() {
			step.value = 1;
			editReport.value = true;
			//@ts-ignore
			noteContent.value = report.value?.note;
		}

		let org = ref({
			value: {
				note: '',
			},
		} as any);
		let assessment_result = ref([]);
		let assessment_report_next_plan = ref(null as any);
		let series = ref([] as number[]);
		let series3 = ref([] as any[]);
		let radarData = ref([] as any[]);
		let categories = ref([] as string[]);
		const radarChartOptions = ref<any>({
			chart: {
				id: 'radarChart',
				type: 'radar',
			},
			title: {
				show: false,
			},
			stroke: {
				width: 0,
			},
			fill: {
				opacity: 0.2,
				colors: ['#65A30D'],
			},
			markers: {
				size: 5,
				hover: {
					size: 10,
				},
				colors: ['#65A30D'],
			},
			dataLabels: {
				enabled: true,
				formatter: function (val: any) {
					return val + '%';
				},
				style: {
					fontFamily: 'Gilrory',
					fontSize: '20px',
					fontWeight: 'bold',
					colors: ['#344256'],
				},
				background: {
					enabled: false,
				},
			},
			yaxis: {
				show: false,
				logBase: 100,
				tickAmount: 10,
				min: 0,
				max: 100,
				labels: {
					show: false,
				},
			},
			xaxis: {
				categories: [],
				labels: {
					show: true,
					style: {
						fontFamily: 'Gilrory',
						fontSize: '14px',
						fontWeight: 600,
						colors: Array(1000).fill('#344256'),
					},
					formatter: function (val: any, index: any) {
						return index?.dataPointIndex + 1;
					},
				},
			},
			tooltip: {
				x: {
					show: true,
					formatter: function (val: any, object: any) {
						return `${object?.dataPointIndex + 1}: ${val}`;
					},
					title: {
						formatter: (seriesName: string) => seriesName,
					},
				},
				y: {
					show: true,
					formatter: function (val: any) {
						return val + '%';
					},
					title: {
						title: {
							formatter: (seriesName: string) => seriesName + ':',
						},
					},
				},
			},
		});
		let emotion_scale = ref([] as any[]);
		let assessments_by_skill = ref([] as any[]);
		let series3Total = ref(0);

		const compare_with_last = ref({
			average_cooperation_scale: '0.0',
			average_emotion_scale: '0.0',
			average_result: '0.0',
		});

		const attendance = ref({
			attendance_compare_with_last_percent: '0.0',
			attendance_percent: '100.0',
			completed: 2,
			total: 2,
		});
		let noteContent = ref('');
		let noteUpdateError = ref('');
		let successData = ref(false);
		let modalIsOpen = ref(false);
		let modalType = ref('');
		let plan: any = ref(null);

		async function submit(data: any, isUpdate: any) {
			plan.value = data;
			reportData.value = {
				//@ts-ignore
				student: computed(() => report.value?.student?.full_name),
				//@ts-ignore
				monthly_start: computed(() => monthly_start.value),
				//@ts-ignore
				inserted_at: computed(() => report.value?.completed_at),
				staff: computed(
					//@ts-ignore
					() => monthly_start.value?.submitted_by_staff?.full_name
				),
				report_name: `KẾ HOẠCH ${report.value?.report_for_plan?.order}`,
				report_name_next: `KẾ HOẠCH CAN THIỆP ${report.value?.next_plan_order}`,
				report_date: plan.value?.apply_at,
			};
			successData.value = false;
			if (noteContent.value?.length <= 0) {
				return (noteUpdateError.value =
					'Bạn cần hoàn tất nhận xét chung của tháng để hoàn thành báo cáo');
			}

			if (isUpdate) {
				await confirmSubmit();
				return;
			}
			modalIsOpen.value = true;
			modalType.value = _MODAL_TYPE.CONFIRM;
		}

		let emotion_cooperation_result_by_day = ref([] as any[]);
		let emotion_cooperation_result_by_day_emotion = ref([] as any[]);
		let emotion_cooperation_result_by_day_rating = ref([] as any[]);
		let emotion_cooperation_result_by_day_result = ref([] as any[]);
		let emotion_cooperation_result_by_day_labels = ref([] as any[]);
		let emotion_cooperation_result_by_day_data = ref([
			{
				name: 'Cảm xúc',
				type: 'column',
				data: [0, 0, 0, 0, 0],
			},
			{
				name: 'Sự tham gia',
				type: 'column',
				data: [0, 0, 0, 0, 0],
			},
			{
				name: 'Kết quả buổi học',
				type: 'line',
				data: [0, 0, 0, 0, 0],
			},
		] as any[]);
		let emotion_cooperation_result_by_day_data2 = ref([
			{
				name: 'Tháng 12/2023',
				type: 'column',
				data: [0, 0, 0, 0, 0],
			},
			{
				name: 'Tháng 12/2023',
				type: 'column',
				data: [0, 0, 0, 0, 0],
			},
		] as any[]);

		const mixChart = ref(null as any);
		const mixChart2 = ref(null as any);

		const stats = ref({
			total_goal_count: 0,
			total_skill_count: 0,
		});

		async function getVideo(month_start: any, month_end: any) {
			try {
				const res = await $api.student.getVideoFromParents(id.value, {
					month_start: month_start,
					month_end: month_end,
				});
				listVideo.value = res.data.data;
			} catch (err) {
				await store.dispatch('setAlertMessage', {
					message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				isLoading.value = false;
			}
		}

		async function fetchReport() {
			try {
				emotion_cooperation_result_by_day.value = [];
				emotion_cooperation_result_by_day_emotion.value = [];
				emotion_cooperation_result_by_day_rating.value = [];
				emotion_cooperation_result_by_day_result.value = [];
				emotion_cooperation_result_by_day_labels.value = [];
				emotion_cooperation_result_by_day_data.value = [
					{
						name: 'Kết quả buổi học',
						type: 'line',
						data: [0, 0, 0, 0, 0],
					},
					{
						name: 'Sự tham gia',
						type: 'column',
						data: [0, 0, 0, 0, 0],
					},
					{
						name: 'Cảm xúc',
						type: 'column',
						data: [0, 0, 0, 0, 0],
					},
				];
				emotion_cooperation_result_by_day_data2.value = [
					{
						name: 'Tháng 12/2023',
						type: 'column',
						data: [0, 0, 0, 0, 0],
					},
					{
						name: 'Tháng 01/2024',
						type: 'column',
						data: [0, 0, 0, 0, 0],
					},
				];
				const oldPayload = ref({
					'x-cross': route.query.org_id,
				});

				const { data } = await $api.monthlyReport.monthlyReport(
					id.value,
					monthly_start.value,
					is_old_data.value ? oldPayload.value : null
				);
				const listGroup = ref<any>([]);
				if (data?.data?.id) {
					const {
						data: { data: groups },
					} = await $api.goal.getListGroupWithGoals(
						id.value,
						data?.data?.id
					);
					listGroup.value = groups;
				}

				if (
					listGroup.value?.length &&
					listGroup.value[0].id !== 'no_have_group'
				) {
					skills.value = listGroup.value;
				} else {
					skills.value = data?.skills ?? [];
				}
				skillsShow.value = data?.skills ?? [];
				if (data) {
					if (!data?.data?.id) {
						return createReport();
					}
					report.value = data.data;
					all_goals_reported.value = data.all_goals_reported;

					if (!is_old_data.value) {
						await getToken();
					}
					if (report.value.report_for_plan && !route.query) {
						getVideo(
							report.value?.report_for_plan?.applied_at,
							report.value?.report_for_plan?.done_at
						);
					}
					if (data?.org) {
						org.value = data?.org;
					}
					if (!goalSelectedId.value) {
						goalSelected.value = skills.value[0]?.goals[0];
						goalIndex.value = 0;
						skillIndex.value = 0;
						if (
							skills.value[skillIndex.value]?.goals?.length >
							goalIndex.value + 1
						) {
							goalSelected__next.value =
								skills.value[skillIndex.value]?.goals[
									goalIndex.value + 1
								];
						} else {
							goalSelected__next.value =
								skills.value[skillIndex.value + 1]?.goals[0];
						}
					}
					if (data?.stats) {
						stats.value = data.stats;

						if (data?.stats.compare_with_last) {
							compare_with_last.value =
								data?.stats.compare_with_last;
						}

						if (data?.stats.attendance) {
							attendance.value = data?.stats.attendance;
						}

						if (data?.stats.assessment_report_next_plan) {
							assessment_report_next_plan.value =
								data?.stats.assessment_report_next_plan;
						}
					}
					if (data?.stats?.assessment_results) {
						assessment_result.value = data?.stats?.result;
						emotion_cooperation_result_by_day.value =
							data?.stats?.emotion_cooperation_result_by_day;
						assessments_by_skill.value =
							data?.stats?.assessment_results;
						emotion_scale.value = data?.stats?.emotion_scale;
						emotion_scale.value.forEach((item) => {
							return (series3Total.value += item.count);
						});
						series3.value = emotion_scale.value.map((item) => {
							const r = (item.count * 100) / series3Total.value;
							return Math.round(r * 10) / 10 || 0;
						});
						let a: any = [];
						let count = 0;
						assessment_result.value.forEach(
							(item: { result: string; count: number }) => {
								if (
									item.result === 'Từ chối' ||
									item.result === 'Không có cơ hội' ||
									item.result === 'Không hợp tác'
								) {
									count += item.count;
								} else {
									a.push(item);
								}
							}
						);
						a.push({
							result: 'X',
							count: count,
						});
						series.value = a.map((item: { count: number }) => {
							return item.count;
						});
						emotion_cooperation_result_by_day.value.forEach(
							(item) => {
								emotion_cooperation_result_by_day_emotion.value.push(
									+(+item.emotion_scale / 5) * 100 || null
								);
								emotion_cooperation_result_by_day_rating.value.push(
									+(+item.cooperation_scale / 5) * 100 || null
								);
								emotion_cooperation_result_by_day_result.value.push(
									+item.complete_percent || null
								);
								//@ts-ignore
								emotion_cooperation_result_by_day_labels.value.push(
									_GET_DATE_2(item.date, true)
								);
							}
						);
						emotion_cooperation_result_by_day_data.value[1] = {
							name: 'Cảm xúc',
							type: 'column',
							data: emotion_cooperation_result_by_day_emotion.value,
						};
						emotion_cooperation_result_by_day_data.value[0] = {
							name: 'Sự tham gia',
							type: 'column',
							data: emotion_cooperation_result_by_day_rating.value,
						};
						emotion_cooperation_result_by_day_data.value[2] = {
							name: 'Kết quả buổi học',
							type: 'line',
							data: emotion_cooperation_result_by_day_result.value,
						};
					}
					if (data.skills) {
						radarData.value = [
							{
								name: 'Kết quả',
								data: data.skills.map((skill: any) => {
									return skill?.skill?.skill_percent;
								}),
							},
						];
						radarChartOptions.value.xaxis.categories =
							data.skills.map((skill: any) => {
								return skill?.skill?.name;
							});
						categories.value = data.skills.map((skill: any) => {
							return skill?.skill?.name;
						});
						emotion_cooperation_result_by_day_data2.value[1] = {
							name: `Tháng ${dayjs(data?.data.apply_date).format(
								'MM/YYYY'
							)}`,
							type: 'column',
							data: data?.skills?.map(
								(s: any) => +s.skill?.skill_percent || 0
							),
						};
					}
					if (data?.previous_month_skills) {
						const previous_month_skills: any[] = [];
						data?.skills.forEach((item: any) => {
							const o = data?.previous_month_skills?.find(
								(s: any) => s.skill.id === item.skill.id
							);
							if (o) {
								previous_month_skills.push(
									+o.skill?.skill_percent || 0
								);
							} else {
								previous_month_skills.push(0);
							}
						});
						emotion_cooperation_result_by_day_data2.value[0] = {
							name: `Tháng ${dayjs(
								data?.previous_month_start
							).format('MM/YYYY')}`,
							type: 'column',
							data: previous_month_skills,
						};
					}
				}
			} catch (e) {
				console.log(e);
			} finally {
				isLoading.value = false;
			}
		}

		async function createReport() {
			try {
				await $api.monthlyReport.createMonthlyReport(id.value, {
					month_start: monthly_start.value,
				});
				await fetchReport();
			} catch (e) {
				console.log(e);
			}
		}

		function selectedGoal(
			goal: any,
			skill_index: number,
			goal_index: number
		) {
			goalSelected.value = goal;
			goalIndex.value = goal_index;
			skillIndex.value = skill_index;
		}

		const reportDetail = ref(null);
		const series_ = ref([] as any);
		const seriesData = ref([]);

		// this gives an object with dates as keys
		function groups(array: any) {
			return array.reduce((groups: any, item: any) => {
				const skill = item.skill;
				if (!groups[skill]) {
					groups[skill] = [];
				}
				groups[skill].push(item);
				return groups;
			}, {});
		}

		const assessments_by_skill_group = computed(() => {
			const g: any[] = groups(assessments_by_skill.value);
			const rs: any = Object.keys(g).map((skill: any) => {
				return {
					skill,
					data: g[skill],
				};
			});
			return rs;
		});

		async function fetchGoalAssignmentDetail() {
			try {
				const { data } = await $api.monthlyReport.assessmentReports(
					id.value,
					report.value.id,
					goalSelectedId.value
				);
				reportDetail.value = data.data;
				seriesData.value = await data.data?.assessments.map(
					(item: any) => {
						return +item.complete_percent;
					}
				);
				series_.value = [
					{
						name: 'Kết quả buổi học',
						data: seriesData.value,
					},
				];
			} catch (e) {
				const error =
					e?.response?.data?.errors.join('<br/>') ||
					'Đã có lỗi xảy ra';
				await store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
			}
		}

		function getNextPlan(value: string) {
			switch (value) {
				case 'Tạm dừng mục tiêu':
					return 'stopped';
				case 'Mục tiêu đã hoàn thành':
					return 'completed';
				case 'Lặp lại mục tiêu':
					return 'continued';
			}
		}

		const isEditTargetDetail = ref(false);

		function submitAssignmentReport_update(value: any) {
			submitAssignmentReport(value, true);
		}

		function submitAssignmentReport_create(value: any) {
			submitAssignmentReport(value, false);
		}

		async function submitAssignmentReport(value: any, isUpdate = false) {
			try {
				const payloadCreate = {
					assessment_report: {
						note: value?.note || '',
						goal_id: goalSelectedId.value,
						next_plan: getNextPlan(value.next_plan),
						stopped_reason:
							getNextPlan(value.next_plan) === 'stopped'
								? value?.stopped_reason
								: '',
						stopped_reason_other:
							value.stopped_reason === 'other'
								? value?.stopped_reason_other
								: '',
					},
				};

				const payloadUpdate = {
					assessment_report: {
						note: value?.note || '',
					},
				};

				const res = isUpdate
					? await $api.monthlyReport.updateAssessmentReports(
							id.value,
							report.value.id,
							value.id,
							payloadUpdate
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  )
					: await $api.monthlyReport.createAssessmentReports(
							id.value,
							report.value.id,
							goalSelectedId.value,
							payloadCreate
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  );

				if (res) {
					await store.dispatch('setAlertMessage', {
						message: 'Xác nhận thành công mục tiêu',
					});
					if (
						skills.value[skillIndex.value]?.goals?.length >
						goalIndex.value + 1
					) {
						goalIndex.value++;
					} else {
						goalIndex.value = 0;
						skillIndex.value++;
					}
					goalSelected__next.value =
						skills.value[skillIndex.value]?.goals[goalIndex.value];
					selectedGoal(
						goalSelected__next.value,
						skillIndex.value,
						goalIndex.value
					);
					console.log(
						selectedGoal(
							goalSelected__next.value,
							skillIndex.value,
							goalIndex.value
						)
					);
					await fetchGoalAssignmentDetail();
					return fetchReport();
				}
			} catch (error) {
				let errorField = error?.response?.data?.errors_by_field;
				if (errorField && errorField['stopped_reason']) {
					await store.dispatch('setAlertMessage', {
						message: 'Bạn hãy chọn lý do tạm dừng mục tiêu',
						type: 'danger',
					});
					return;
				}
				if (errorField && errorField['stopped_reason_other']) {
					await store.dispatch('setAlertMessage', {
						message:
							'Lý do tạm dừng ' +
							errorField['stopped_reason_other'].join(', ') +
							'.',
						type: 'danger',
					});
					return;
				}

				await store.dispatch('setAlertMessage', {
					message: 'Đã có lỗi xảy ra. Vui lòng thủ lại.',
					type: 'danger',
				});

				isEditTargetDetail.value = true;
			}
		}

		function exit() {
			modalIsOpen.value = true;
			modalType.value = _MODAL_TYPE.EXIT;
		}

		fetchReport();

		let token = ref('');

		async function getToken() {
			try {
				// @ts-ignore
				if (!report.value.id) {
					return;
				}
				const res = await $api.monthlyReport.getToken(
					// @ts-ignore
					report.value.id,
					id.value
				);
				if (res?.data) {
					token.value = res?.data?.data.token;
				}
			} catch (e) {
				console.log(e);
			}
		}

		function openSendConfirm() {
			modalIsOpen.value = true;
			modalType.value = _MODAL_TYPE.CREATE;
		}

		function cancelSendReport() {
			modalIsOpen.value = false;
			modalType.value = '';
		}

		async function sendReport() {
			try {
				await $api.monthlyReport.sendMonthlyReport(
					id.value,
					report.value?.id
				);
				cancelSendReport();
				await fetchReport();
				await store.dispatch('setAlertMessage', {
					message: 'Gửi kế hoạch đến phụ huynh thành công',
				});
			} catch (err) {
				await store.dispatch('setAlertMessage', {
					message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		}

		function cancel() {
			editReport.value = false;
			noteContent.value = '';
			noteUpdateError.value = '';
			modalIsOpen.value = false;
			modalType.value = '';
			path.value = ' ';
			router.push('/report/monthly-report');
		}

		function shareReport() {
			window.open(`/w/${token.value}`, '_blank');
		}

		const path = ref('');

		function openShare() {
			modalIsOpen.value = true;
			modalType.value = _MODAL_TYPE.COPY;
			path.value = `${window.location.origin}/m/${token.value}`;
		}

		function closeShare() {
			modalIsOpen.value = false;
			path.value = '';
		}

		function doNextPlan() {
			router.push(`/plans/${id.value}/future`);
		}

		async function confirmSubmit() {
			try {
				const isUpdate = report.value?.state === 'completed';
				const res = isUpdate
					? await $api.monthlyReport.updateNoteReport(
							report.value.id,
							id.value,
							{
								monthly_report: {
									note: noteContent.value,
								},
							}
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  )
					: await $api.monthlyReport.submitReport(
							report.value.id,
							id.value,
							{
								monthly_report: {
									note: noteContent.value,
								},
								plan: {
									apply_now: plan.value?.apply_now || false,
									name: plan.value?.name || '',
									apply_date: plan.value?.apply_at || '',
								},
							}
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  );
				if (res?.data) {
					successData.value = true;
					await router.push(
						`/report/monthly-report/${id.value}?month_start=${monthly_start.value}`
					);
					await store.dispatch('setAlertMessage', {
						message: isUpdate
							? 'Báo cáo đã được cập nhật thành công'
							: 'Làm báo cáo thành công',
					});
					await fetchReport();
				}
			} catch (e) {
				modalIsOpen.value = true;
				modalType.value = _MODAL_TYPE.ERROR;
				errorData.value = {
					error:
						e.response.data?.errors.join('<br />') ||
						'Đã có lỗi xảy ra',
					reportError: e.response.data?.data || [],
				};
			}
		}

		function cancelConfirm() {
			modalIsOpen.value = false;
		}

		function cancelConfirmCompleted() {
			editReport.value = false;
			noteContent.value = '';
			noteUpdateError.value = '';
			modalIsOpen.value = false;
			modalType.value = '';
			path.value = ' ';
			modalIsOpen.value = false;
		}

		let reportData = ref({
			//@ts-ignore
			student: computed(() => report.value?.student?.full_name),
			monthly_start: computed(() => monthly_start.value),
			//@ts-ignore
			inserted_at: computed(() => report.value?.completed_at),
			staff: computed(
				//@ts-ignore
				() => monthly_start.value?.submitted_by_staff?.full_name
			),
			reportName: report.value?.next_plan_order,
			report_date: plan.value?.apply_at,
		});

		function reason(as: any) {
			const stopped_reason = as?.stopped_reason
				? as?.stopped_reason.toLowerCase()
				: '';
			const stopped_reason_other = as?.stopped_reason_other || '';
			const other =
				stopped_reason !== 'Khác' && stopped_reason !== 'other'
					? stopped_reason
					: stopped_reason_other;
			return as?.next_plan !== 'Tạm dừng mục tiêu'
				? as?.next_plan
				: `${as?.next_plan} ${other ? ' vì ' + other : ''}`;
		}

		function countComplete(s: any) {
			let count = 0;
			s.goals.forEach((goal: any) => {
				if (goal.completed) {
					count++;
				}
			});
			return count;
		}

		function isChildSelected(s: any) {
			return s.goals.find((item: any) => {
				const id = item.goal_id || item.id;
				const valueId =
					goalSelected.value?.goal_id || goalSelected.value?.id;
				return id === valueId;
			});
		}

		function isCompare(item: any, goal: any) {
			if (!item || !goal) {
				return false;
			}
			const id = item.goal_id || item.id;
			const valueId = goal.goal_id || goal.id;
			return id === valueId;
		}

		// goal report step 1
		const isSubShow = ref(0);
		const isMTShow = ref(true);
		// goal report step 1

		onMounted(() => {
			window.scrollTo(0, 0);
			if (window.screen.width < 1024) {
				isMTShow.value = false;
			}
		});

		const isDone = ref(false);

		function openNewLink(assessments: any) {
			let url = router.resolve(
				`/report/lesson/${assessments?.lesson_report?.date}?student_id=${id.value}`
			);
			window.open(url.href, '_blank');
		}

		const dateData = computed(() => {
			const join_date = route.query.join_date;
			const left_date = route.query.left_date;
			if (!join_date) {
				return '';
			}
			return `${_GET_DATE_2(join_date)} - ${
				left_date ? _GET_DATE_2(left_date) : 'Hiện tại'
			} | Đã học ${_MONTH_DIFF(join_date, left_date)} tháng`;
		});

		function exitOldData() {
			const new_id = route.query.new_id;
			const r = router.resolve({
				path: `/record/student/${new_id}/staff-list`,
			});
			window.location.assign(r.href);
		}

		const disableAi = ref(false);
		async function generateReport() {
			try {
				if (disableAi.value == true) return;
				disableAi.value = true;
				const res = await $api.monthlyReport.generateReport(
					id.value,
					monthly_start.value
				);
				noteContent.value = res.data.data;
			} catch (e) {
				console.log(e);
			}
		}

		watch(
			mixChart,
			() => {
				const optimalColumnWidthPercent =
					5 +
					15 /
						(1 +
							30 *
								Math.exp(
									-emotion_cooperation_result_by_day_labels
										.value?.length / 3
								));

				setTimeout(() => {
					mixChart.value?.updateOptions({
						labels: emotion_cooperation_result_by_day_labels.value,
						plotOptions: {
							bar: {
								columnWidth: optimalColumnWidthPercent + '%',
							},
						},
					});
				}, 1000);
			},
			{ immediate: true, deep: true }
		);

		watch(
			mixChart2,
			() => {
				const optimalColumnWidthPercent =
					12 +
					16 / (1 + 30 * Math.exp(-categories.value?.length / 3));

				setTimeout(() => {
					mixChart2.value?.updateOptions({
						labels: categories.value,
						plotOptions: {
							bar: {
								columnWidth: optimalColumnWidthPercent + '%',
							},
						},
					});
				}, 1000);
			},
			{ immediate: true, deep: true }
		);

		const isAdmin = computed(() => store.getters.isAdmin);
		const enableAiButton = computed(
			() => store.state.profile.enable_ai_button
		);

		return {
			isAdmin,
			dateData,
			exitOldData,
			cancelSendReport,
			openSendConfirm,
			sendReport,
			openNewLink,
			isDone,
			isChildSelected,
			isSubShow,
			isMTShow,
			countComplete,
			editReport,
			reason,
			step,
			org,
			alertShow,
			report,
			reportDetail,
			skills,
			skillsShow,
			goalSelected,
			all_goals_reported,
			selectedGoal,
			disableAi,
			generateReport,
			enableAiButton,
			series_,
			chartOptions_: {
				colors: ['#2AA797'],
				chart: {
					type: 'line',
					zoom: {
						enabled: false,
					},
					animations: {
						enabled: false,
					},
				},
				stroke: {
					width: 3,
				},
				labels: [
					1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
					18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
				],
				yaxis: {
					labels: {
						show: false,
					},
					max: 100,
					min: 0,
					tickAmount: 5,
				},
				grid: {
					show: true,
					borderColor: '#F1F5F9',
				},
				markers: {
					size: 8,
					strokeColors: '#fff',
					strokeWidth: 2,
				},
			},
			series3,
			mixChart,
			mixChart2,
			chartOptions3: {
				colors: ['#9EDF93', '#D0DB54', '#FFE68C', '#FF9D6D', '#FF6967'],
				chart: {
					width: 380,
					type: 'polarArea',
				},
				labels: [
					'Vui vẻ',
					'Bình thường',
					'Khó chịu',
					'Tức giận',
					'Bùng nổ',
				],
				fill: {
					opacity: 1,
					colors: [
						'#9EDF93',
						'#D0DB54',
						'#FFE68C',
						'#FF9D6D',
						'#FF6967',
					],
				},
				stroke: {
					lineCap: 'round',
				},
				yaxis: {
					show: false,
				},
				tooltip: {
					fillSeriesColor: true,
					y: {
						formatter: function (value: any) {
							if (!value || value == 'null') {
								return 'Không thực hiện';
							}
							return value + '%';
						},
					},
				},
				legend: {
					show: false,
				},
				dataLabels: {
					enabled: false,
				},
				plotOptions: {
					polarArea: {
						rings: {
							strokeWidth: 0,
						},
						spokes: {
							strokeWidth: 0,
						},
					},
				},
			},
			assessment_result,
			assessments_by_skill_group,
			series,
			series3Total,
			mixChartOptions: {
				chart: {
					type: 'line',
					stacked: false,
				},
				dataLabels: {
					enabled: true,
					enabledOnSeries: [0, 1],
					offsetY: -10,
					background: {
						enabled: true,
						foreColor: '#334155',
					},
					formatter: function (val: any) {
						return val + '%';
					},
				},
				stroke: {
					width: [0.5, 0.5, 4],
				},
				yaxis: [
					{
						opposite: false,
						axisTicks: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
							formatter: function (value: any) {
								if (!value || value == 'null') {
									return 'Không thực hiện';
								}
								return value + '%';
							},
						},
						title: {
							show: false,
						},
						max: 100,
					},
					{
						opposite: false,
						axisTicks: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
							formatter: function (value: any) {
								if (!value || value == 'null') {
									return 'Không thực hiện';
								}
								return value + '%';
							},
						},
						title: {
							show: false,
						},
						max: 100,
					},
					{
						opposite: false,
						axisTicks: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
							formatter: function (value: any) {
								if (!value || value == 'null') {
									return 'Không thực hiện';
								}
								return value + '%';
							},
						},
						title: {
							show: false,
						},
						max: 100,
					},
				],
				tooltip: {
					fixed: {
						enabled: true,
						position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
						offsetY: 30,
						offsetX: 60,
					},
				},
				legend: {
					horizontalAlign: 'left',
					offsetX: 40,
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: '20%',
						borderRadius: 4,
					},
				},
				labels: [],
				colors: ['#FFD270', '#DA8F8F', '#6ED0B9'],
				markers: {
					size: 8,
					strokeColors: '#fff',
					strokeWidth: 2,
				},
			},
			mixChartOptions2: {
				chart: {
					type: 'line',
					stacked: false,
				},
				dataLabels: {
					enabled: true,
					enabledOnSeries: [0, 1],
					offsetY: -10,
					background: {
						enabled: true,
						foreColor: '#334155',
					},
					formatter: function (val: any) {
						return val + '%';
					},
				},
				stroke: {
					width: [0.5, 0.5],
				},
				yaxis: [
					{
						opposite: false,
						axisTicks: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
							formatter: function (value: any) {
								if (!value || value == 'null') {
									return 'Không thực hiện';
								}
								return value + '%';
							},
						},
						title: {
							show: false,
						},
						max: 100,
					},
					{
						opposite: false,
						axisTicks: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
							formatter: function (value: any) {
								if (!value || value == 'null') {
									return 'Không thực hiện';
								}
								return value + '%';
							},
						},
						title: {
							show: false,
						},
						max: 100,
					},
				],
				xaxis: {
					categories: [],
				},
				tooltip: {
					fixed: {
						enabled: true,
						position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
						offsetY: 30,
						offsetX: 60,
					},
				},
				legend: {
					horizontalAlign: 'left',
					offsetX: 40,
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: '10%',
						borderRadius: 4,
						distributed: false,
					},
				},
				labels: [],
				colors: ['#F59E0B', '#2AA797'],
				markers: {
					size: 8,
					strokeColors: '#fff',
					strokeWidth: 2,
				},
			},
			radarData,
			radarChartOptions,
			emotion_scale,
			chartOptions: {
				dataLabels: {
					enabled: false,
				},
				colors: ['#7AD2C6', '#93BF56', '#F8BB54', '#F47C7C', '#E5E7EB'],
				labels: [
					'Đạt 80 - 100%',
					'Đạt 55 - 75%',
					'Đạt 30 - 50%',
					'Đạt 5 - 25%',
					'X',
				],
				legend: {
					show: false,
				},
				tooltip: {
					fillSeriesColor: true,
					y: {
						formatter: function (value: any) {
							return value + ' mục tiêu';
						},
					},
				},
				stroke: {
					lineCap: 'round',
				},
			},
			chartOptions2_1: {
				chart: {
					type: 'radialBar',
				},
				plotOptions: {
					radialBar: {
						hollow: {
							size: '54%',
						},
						dataLabels: {
							name: {
								offsetY: -10,
								show: true,
								color: '#65758B',
								fontWeight: 'normal',
								fontSize: '13px',
							},
							value: {
								color: '#48566A',
								fontSize: '24px',
								show: true,
								fontWeight: 'bold',
							},
						},
					},
				},
				fill: {
					colors: '#6ED0B9',
				},
				stroke: {
					lineCap: 'round',
				},
				labels: ['Kết quả'],
			},
			chartOptions2_2: {
				chart: {
					type: 'radialBar',
				},
				plotOptions: {
					radialBar: {
						hollow: {
							size: '54%',
						},
						dataLabels: {
							name: {
								offsetY: -10,
								show: true,
								color: '#65758B',
								fontWeight: 'normal',
								fontSize: '13px',
							},
							value: {
								color: '#48566A',
								fontSize: '24px',
								show: true,
								fontWeight: 'bold',
							},
						},
					},
				},
				fill: {
					colors: '#FFD270',
				},
				stroke: {
					lineCap: 'round',
				},
				labels: ['Sự tham gia'],
			},
			chartOptions2_3: {
				chart: {
					type: 'radialBar',
				},
				plotOptions: {
					radialBar: {
						hollow: {
							size: '54%',
						},
						dataLabels: {
							name: {
								offsetY: -10,
								show: true,
								color: '#65758B',
								fontWeight: 'normal',
								fontSize: '13px',
								fontFamily: 'SVN-Gilroy',
							},
							value: {
								color: '#48566A',
								fontSize: '24px',
								show: true,
								fontWeight: 'bold',
							},
						},
					},
				},
				fill: {
					colors: '#DA8F8F',
				},
				stroke: {
					lineCap: 'round',
				},
				labels: ['Cảm xúc'],
			},
			emotion_cooperation_result_by_day_data,
			emotion_cooperation_result_by_day_data2,
			modalIsOpen,
			modalType,
			modalTypes: computed(() => _MODAL_TYPE),
			attendance,
			compare_with_last,
			noteUpdateError,
			noteContent,
			successData,
			reportData,
			errorData,
			path,
			monthly_start,
			isEditTargetDetail,
			routerName: computed(() => _ROUTER_NAME),
			assessment_report_next_plan,
			stats,
			isLoading,
			submit,
			cancel,
			cancelConfirm,
			cancelConfirmCompleted,
			confirmSubmit,
			openShare,
			edit,
			exit,
			closeShare,
			shareReport,
			submitAssignmentReport_update,
			submitAssignmentReport_create,
			doNextPlan,
			is_old_data,
			categories,
			isCompare,
			tabs,
			tabSelected,
			listVideo,
		};
	},

	mixins: [downloadCSVPlanReport],
});
