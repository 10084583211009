<template>
	<div v-if="isLoading || loaded" class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
	<div v-else id="plans_pdf">
		<div class="p-6 md-down:px-4">
			<div class="__goal-management-student-detail__header pt-5">
				<div class="flex justify-between items-center w-full">
					<div class="flex items-center">
						<img
							style="min-width: 56px"
							class="
								shadow-sm
								inline-block
								h-14
								w-14
								rounded-full
							"
							:src="student?.profile_photo?.w200"
							v-if="student?.profile_photo?.w200"
						/>
						<span
							style="min-width: 56px"
							v-else
							class="
								inline-flex
								items-center
								justify-center
								h-14
								w-14
								rounded-full
							"
							:style="{
								background:
									student?.profile_photo?.default?.color,
							}"
						>
							<span
								class="
									font-medium
									leading-none
									text-white text-4xl
								"
								>{{
									student?.profile_photo?.default?.initial
								}}</span
							>
						</span>
						<div class="ml-4">
							<div
								class="
									font-bold
									text-neutral-700 text-4xl
									md-down:text-2xl
								"
							>
								{{ student?.name }}
							</div>
							<div
								v-if="student?.age"
								class="
									text-green-500
									font-semibold
									text-xs
									uppercase
								"
							>
								{{ student?.age }}
								{{
									student?.learning_time
										? `| ${student?.learning_time}`
										: ''
								}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="p-6 mt-6">
			<div class="p-6 bg-white shadow-sm rounded-2xl mb-10">
				<div>
					<div class="flex items-center justify-between mb-8">
						<div class="font-semibold text-neutral-600">
							{{ data?.name || 'Kế hoạch' }}
						</div>
						<div class="flex items-center">
							<div
								class="font-semibold text-neutral-600 mr-8"
								v-if="data?.applied_at"
							>
								Áp dụng:
								{{ $filters.formatDate(data?.applied_at) }}
							</div>
						</div>
					</div>
				</div>
				<div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6">
					<div
						class="
							p-6
							border
							lg:rounded-l-lg
							md:rounded-tl-lg
							border-neutral-200
							w-full
							md-down:rounded-t md-down:rounded-b-none
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								src="@/assets/images/icons/abc.png"
								alt=""
								width="32"
								height="32"
								class="mr-2"
								style="height: 32px"
							/>
							{{ skills_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Tổng số lĩnh vực
							</span>
						</div>
					</div>
					<div
						class="
							p-6
							border border-neutral-200
							w-full
							md:border-l-0
							md-down:border-t-0
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								src="@/assets/images/icons/light.png"
								alt=""
								width="32"
								height="32"
								class="mr-2"
								style="height: 32px"
							/>
							{{ goals_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Tổng số mục tiêu</span
							>
						</div>
					</div>
					<div
						class="
							p-6
							border border-neutral-200
							w-full
							md:rounded-tr-lg
							lg:rounded-tr-none
							md:border-l-0
							md-down:border-t-0
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								src="@/assets/images/icons/light.png"
								alt=""
								width="32"
								height="32"
								class="mr-2"
								style="height: 32px"
							/>
							{{ continued_goals_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Mục tiêu lặp lại</span
							>
						</div>
					</div>
					<div
						class="
							p-6
							border border-neutral-200
							w-full
							lg:border-l-0
							lg-down:border-t-0
							md:rounded-bl-lg
							lg:rounded-bl-none
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								src="@/assets/images/icons/light.png"
								alt=""
								width="32"
								height="32"
								class="mr-2"
								style="height: 32px"
							/>
							{{ new_goals_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Mục tiêu mới</span
							>
						</div>
					</div>
					<div
						class="
							p-6
							border border-neutral-200
							w-full
							md:border-l-0
							lg-down:border-t-0
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								src="@/assets/images/icons/light.png"
								alt=""
								width="32"
								height="32"
								class="mr-2"
								style="height: 32px"
							/>
							{{ goals_from_stopped_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Từ MT tạm dừng</span
							>
						</div>
					</div>
					<div
						class="
							p-6
							border
							md:border-l-0
							lg:rounded-r-lg
							md:rounded-br-lg
							border-neutral-200
							w-full
							lg-down:border-t-0
							md-down:rounded-b md-down:rounded-t-none
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								src="@/assets/images/icons/light.png"
								alt=""
								width="32"
								height="32"
								class="mr-2"
								style="height: 32px"
							/>
							{{ goals_from_lib_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Từ Ngân hàng MT</span
							>
						</div>
					</div>
				</div>
				<div class="mt-4 flex justify-end items-center text-gray-700">
					<div
						v-if="data && data.sent_at && data.sent_by_staff"
						class="flex gap-2"
					>
						<router-link
							:to="`/record/employee/${data.sent_by_staff?.id}`"
						>
							<div class="flex items-center">
								<div>
									<img
										class="
											shadow-sm
											inline-block
											h-6
											w-6
											rounded-full
										"
										:src="
											data.sent_by_staff?.profile_photo
												?.w200
										"
										alt=""
										v-if="
											data.sent_by_staff?.profile_photo
												?.w200
										"
									/>
									<span
										v-else
										class="
											inline-flex
											items-center
											justify-center
											h-6
											w-6
											rounded-full
										"
										:style="{
											background:
												data.sent_by_staff
													?.profile_photo?.default
													?.color,
										}"
									>
										<span
											class="
												font-medium
												leading-none
												text-white text-xs
											"
											>{{
												data.sent_by_staff
													?.profile_photo?.default
													?.initial
											}}</span
										>
									</span>
								</div>
								<div class="ml-4 text-blue-600 font-semibold">
									{{ data.sent_by_staff.name }}
								</div>
							</div>
						</router-link>
						đã gửi KH tới PH
						<span>{{ $filters.formatDateTime(data.sent_at) }}</span>
					</div>
					<div v-else></div>
				</div>
			</div>
			<div v-if="goals && goals.length > 0">
				<div>
					<div
						class="mb-10"
						v-for="(goal, index) in goals"
						:key="index"
					>
						<div
							class="font-semibold text-lg text-neutral-600 mb-4"
						>
							{{ index + 1 }}. {{ goal.skill.name }} ({{
								goal.goals.length
							}})
						</div>
						<div class="shadow-sm sm:rounded-2xl overflow-hidden">
							<div
								class="
									__record__table
									overflow-x-auto
									scroll-bar
								"
							>
								<div
									class="
										overflow-hidden
										sm:rounded-t-2xl
										w-fit
										min-w-full
									"
								>
									<table
										class="
											min-w-full
											divide-y
											text-neutral-200
										"
									>
										<thead class="bg-neutral-100 h-16">
											<tr>
												<th
													scope="col"
													class="
														px-6
														py-3
														text-left text-xs
														font-semibold
														text-neutral-600
														uppercase
														tracking-wider
														w-16
														whitespace-nowrap
													"
												>
													STT
												</th>
												<th
													scope="col"
													class="
														px-6
														py-3
														text-left text-xs
														font-semibold
														text-neutral-600
														uppercase
														tracking-wider
														whitespace-nowrap
													"
													style="min-width: 160px"
												>
													Nội dung
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(g, i) in goal.goals"
												:key="g.id"
												:class="
													i % 2 === 0
														? 'bg-white'
														: 'bg-neutral-50'
												"
												class="text-neutral-700"
											>
												<td
													class="
														px-6
														py-4
														whitespace-nowrap
														text-neutral-700
													"
												>
													<div class="ml-2">
														{{ i + 1 }}
													</div>
												</td>
												<td
													class="
														px-6
														py-4
														text-neutral-700
													"
												>
													<div
														class="
															flex
															items-center
														"
													>
														<router-link
															:to="`/goal/${id}/${
																g.goal_id ||
																g.id
															}`"
														>
															<div
																class="
																	mr-2
																	hover:text-yellow-700
																	hover:underline
																"
															>
																{{
																	g.goal_name
																}}
															</div>
														</router-link>
														<TooltipGoalDetail
															:goal-id="
																g.goal_id ||
																g.id
															"
															:student-id="id"
														/>
													</div>
													<div
														class="
															flex
															gap-2
															flex-wrap
														"
														style="max-width: 360px"
													>
														<div
															class="
																mt-1
																py-0.5
																px-2.5
																rounded-full
																bg-neutral-200
																text-neutral-700
																h-fit
																w-fit
																whitespace-nowrap
																font-semibold
																shadow-input
															"
															style="
																font-size: 10px;
															"
															v-if="g?.raw_source"
														>
															{{
																g?.display_source
															}}
														</div>
														<template
															v-if="
																g?.assistance_levels &&
																org.enable_goal_assistance_level
															"
														>
															<div
																v-for="ass in g?.assistance_levels"
																:key="ass"
																class="
																	flex
																	gap-2
																"
															>
																<div
																	class="
																		mt-1
																		py-0.5
																		px-2.5
																		rounded-full
																		bg-pink-100
																		whitespace-nowrap
																		text-neutral-700
																		h-fit
																		w-fit
																		font-semibold
																		shadow-input
																	"
																	style="
																		font-size: 10px;
																	"
																>
																	{{ ass }}
																</div>
															</div>
														</template>
													</div>
													<div
														v-if="
															g.parent
																?.sub_goal_count >
															0
														"
														class="
															goal-block
															flex
															gap-3
															mt-2
														"
													>
														<img
															class="w-3 h-3 mt-1"
															src="@/assets/images/icons/goal-arrow.png"
															alt=""
														/>
														<div>
															<div
																class="
																	text-neutral-700
																"
															>
																{{
																	g.parent
																		?.name
																}}
															</div>
															<div
																class="
																	text-neutral-400
																	text-xs
																	font-semibold
																"
															>
																{{
																	g.parent
																		?.sub_goal_count
																}}
																MỤC TIÊU
															</div>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail';
import Loading from '@/components/common/Loading.vue';

const route = useRoute();
const student = ref(null);
const store = useStore();
const data = ref(null);
const goals = ref([]);
const goals_count = ref(0);
const skills_count = ref(0);
const continued_goals_count = ref(0);
const goals_from_lib_count = ref(0);
const goals_from_stopped_count = ref(0);
const new_goals_count = ref(0);
const loaded = ref(false);
const isLoading = ref(false);
const org = computed(() => store.state.profile.current_org);
const id = computed(() => String(route.params.id || ''));
const student_id = computed(() => route.params.student_id);

onMounted(() => {
	planActive();
	fetchStudent();
});

async function fetchStudent() {
	try {
		loaded.value = true;
		const response = await fetch(
			process.env.VUE_APP_BASE_URL +
				`v1/staff/students/${student_id.value}`,
			{
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'x-access-token': route?.query?.token,
					'x-subdomain': route?.query?.subdomain,
				},
			}
		);
		const data = await response.json();
		student.value = data.data;
		loaded.value = false;
	} catch (e) {
		await store.dispatch('setAlertMessage', {
			message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
			type: 'danger',
		});
	}
}

async function planActive() {
	try {
		isLoading.value = true;
		const response = await fetch(
			process.env.VUE_APP_BASE_URL +
				`v1/staff/students/${student_id.value}/plans/${id.value}`,
			{
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'x-access-token': route?.query?.token,
					'x-subdomain': route?.query?.subdomain,
				},
			}
		);
		const data = await response.json();

		if (data.skills) {
			goals.value = data.skills.continued;
		}
		if (data.stats) {
			goals_count.value = data.stats.goals_count;
			skills_count.value = data.stats.skills_count;
			continued_goals_count.value = data.stats.continued_goals_count;
			goals_from_lib_count.value = data.stats.goals_from_lib_count;
			goals_from_stopped_count.value =
				data.stats.goals_from_stopped_count;
			new_goals_count.value = data.stats.new_goals_count;
		}
		isLoading.value = false;
	} catch (e) {
		console.log(e);
	}
}
</script>
