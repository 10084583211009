<template>
	<div v-if="!isLoading">
		<div v-if="goals && goals.length > 0">
			<div>
				<div class="mb-10" v-for="(goal, index) in goals" :key="index">
					<div class="font-semibold text-lg text-neutral-600 mb-4">
						{{ index + 1 }}. {{ goal.skill.name }} ({{
							goal.goals.length
						}})
					</div>
					<div class="shadow-sm sm:rounded-2xl overflow-hidden">
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									overflow-hidden
									sm:rounded-t-2xl
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-8
													whitespace-nowrap
												"
											></th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-16
													whitespace-nowrap
												"
											>
												STT
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
												style="min-width: 400px"
											>
												Nội dung
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												TRẠNG THÁI
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												VỊ TRÍ
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												NGÀY TẠO
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												NGÀY HOÀN THÀNH
											</th>
										</tr>
									</thead>
									<tbody>
										<template
											v-for="(g, i) in goal.goals"
											:key="g.id"
										>
											<tr
												@click="
													$router.push(
														`/goal/${id}/${g.id}`
													)
												"
												:class="
													i % 2 === 0
														? 'bg-white'
														: 'bg-neutral-50'
												"
												class="
													text-neutral-700
													hover:bg-orange-50
													cursor-pointer
												"
											>
												<td
													class="px-6 py-4 w-8"
													@click.stop="openSubGoal(g)"
												>
													<div
														v-if="
															g?.sub_goal_count >
															0
														"
														class="w-4"
													>
														<img
															class="w-full"
															v-if="g?.open"
															src="@/assets/images/icons/arrow-up.png"
															alt=""
														/>
														<img
															class="w-full"
															v-else
															src="@/assets/images/icons/arrow-down.png"
															alt=""
														/>
													</div>
												</td>
												<td
													class="
														px-6
														py-4
														whitespace-nowrap
														text-neutral-700
													"
												>
													<div class="ml-2">
														{{ i + 1 }}
													</div>
												</td>
												<td
													class="
														px-6
														py-4
														text-neutral-700
													"
													style="min-width: 400px"
												>
													<div
														class="
															flex
															items-center
															justify-between
															gap-2
														"
													>
														<div
															v-html="g.name"
														></div>
														<TooltipGoalDetail
															:goal-id="
																g.goal_id ||
																g.id
															"
															:student-id="
																id || ''
															"
														/>
													</div>
													<div class="flex gap-2">
														<template
															v-if="
																g?.assistance_levels &&
																org.enable_goal_assistance_level
															"
														>
															<div
																v-for="ass in g?.assistance_levels"
																:key="ass"
																class="
																	flex
																	gap-2
																"
															>
																<div
																	class="
																		mt-1
																		py-0.5
																		px-2.5
																		rounded-full
																		bg-pink-100
																		whitespace-nowrap
																		text-neutral-700
																		h-fit
																		w-fit
																		font-semibold
																		shadow-input
																	"
																	style="
																		font-size: 10px;
																	"
																>
																	{{ ass }}
																</div>
															</div>
														</template>
													</div>
													<div
														v-if="
															g?.sub_goal_count >
															0
														"
														class="mt-2"
													>
														<div
															class="
																text-neutral-400
																text-xs
																font-semibold
															"
														>
															{{
																g?.sub_goal_count
															}}
															MỤC TIÊU
														</div>
													</div>
													<div
														v-if="
															g?.is_parent &&
															(g?.has_media ||
																g?.number_of_assessment >
																	0 ||
																g?.completed_at)
														"
														class="
															flex
															items-center
															gap-2
															mt-2
														"
													>
														<div
															v-if="g?.has_media"
														>
															<img
																alt=""
																src="@/assets/images/icons/has_media.png"
															/>
														</div>
														<div
															v-if="
																g?.number_of_assessment >
																0
															"
															class="
																text-neutral-400
																text-xs
																font-semibold
															"
														>
															ĐÃ HỌC
															{{
																g?.number_of_assessment
															}}
															NGÀY
														</div>
														<div
															v-if="
																g?.completed_at
															"
														>
															<img
																alt=""
																src="@/assets/images/icons/has_taught.png"
															/>
														</div>
													</div>
													<!-- <div
														v-if="g?.is_parent"
														class="
															flex
															items-center
															gap-2
															mt-2
														"
													>
														<div
															class="
																text-neutral-400
																text-xs
																font-semibold
															"
														>
															KẾT QUẢ
														</div>
														<div
															class="
																progress-bar
																overflow-hidden
															"
														>
															<div
																:style="{
																	background:
																		TAG_COLOR(
																			g?.complete_percent
																		),
																	width:
																		g?.complete_percent +
																		'%',
																}"
																class="h-full"
															></div>
														</div>
														<div
															class="
																text-neutral-400
																text-xs
																font-semibold
															"
														>
															{{
																g?.complete_percent
															}}%
														</div>
													</div> -->
												</td>
												<td
													class="
														px-6
														py-4
														whitespace-nowrap
														text-neutral-700
													"
												>
													<div v-if="g?.is_parent">
														-
													</div>
													<div
														v-else
														v-html="
															getStatus(
																g.state
															)
														"
													></div>
												</td>
												<td
													class="
														px-6
														py-4
														whitespace-nowrap
														text-neutral-700
													"
												>
													<div>
														{{
															g?.is_parent
																? '-'
																: g?.tab_location
														}}
													</div>
												</td>
												<td
													class="
														px-6
														py-4
														whitespace-nowrap
														text-neutral-700
													"
												>
													<div v-if="g.inserted_at">
														{{
															$filters.formatDateDDMM(
																g?.inserted_at
															)
														}}
													</div>
													<div v-else>-</div>
												</td>
												<td
													class="
														px-6
														py-4
														whitespace-nowrap
														text-neutral-700
													"
												>
													<div v-if="g?.completed_at">
														{{
															$filters.formatDateDDMM(
																g?.completed_at
															)
														}}
													</div>
													<div v-else>-</div>
												</td>
											</tr>
											<tr
												class="
													text-neutral-700
													cursor-pointer
												"
												:class="
													i % 2 === 0
														? 'bg-white'
														: 'bg-neutral-50'
												"
												v-if="g.open"
											>
												<td colspan="8" class="pl-16">
													<child-goal
														:data="g.sub_goals"
														:id="id"
													/>
												</td>
											</tr>
										</template>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<template v-else>
			<div
				class="w-full h-full flex items-center justify-center flex-col"
			>
				<img
					src="@/assets/images/illustrations/not_found.png"
					alt=""
					height="250"
					width="250"
				/>
				<div
					class="text-neutral-500 text-lg text-center"
					style="max-width: 700px"
				>
					Không có mục tiêu nào
				</div>
			</div>
		</template>
	</div>
	<div v-else class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import { $api } from '@/services';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail';
import Loading from '@/components/common/Loading';
import ChildGoal from './ChildGoal.vue';
import { TAG_COLOR } from '@/helper/tag';

export default defineComponent({
	name: 'AllPlan',

	components: { Loading, TooltipGoalDetail, ChildGoal },

	data() {
		return {
			goals: [],
			isLoading: true,
		};
	},

	computed: {
		id() {
			return this.$route.params.id;
		},

		org() {
			return this.$store.state.profile.current_org;
		},

		oldPayload() {
			return {
				'x-cross': this.$route.query.org_id,
			};
		},

		is_old_data() {
			return this.$route.query.is_old_data === 'true';
		},
	},

	created() {
		this.planAll();
	},

	methods: {
		TAG_COLOR,
		async planAll() {
			try {
				const {
					data: { data },
				} = await $api.monthlyReport.planAll(
					this.id,
					this.is_old_data ? this.oldPayload : null
				);
				this.goals = data ?? [];
				this.isLoading = false;
			} catch (e) {
				console.log(e);
			}
		},

		getStatus(state) {
			switch (state) {
				case 'completed':
					return `<div class=" flex items-center gap-2">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5ZM12.0303 5.46967C11.7374 5.17678 11.2625 5.17678 10.9697 5.46967C10.9626 5.47674 10.9559 5.48424 10.9498 5.4921L7.47739 9.91674L5.38387 7.82322C5.09097 7.53033 4.6161 7.53033 4.32321 7.82322C4.03031 8.11612 4.03031 8.59099 4.32321 8.88388L6.96965 11.5303C7.26255 11.8232 7.73742 11.8232 8.03031 11.5303C8.03684 11.5238 8.04299 11.5169 8.04875 11.5097L12.041 6.51947C12.3232 6.22582 12.3196 5.75897 12.0303 5.46967Z" fill="#2AA797"/>
                  </svg>
                  <span class="text-neutral-700"> Hoàn thành </span>
                  </div>`;
				case 'stopped':
					return `<div class=" flex items-center gap-2">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5ZM12.1667 8.49677C12.1653 8.13006 11.942 7.83333 11.6667 7.83333H4.66667C4.39052 7.83333 4.16667 8.13181 4.16667 8.5C4.16667 8.86819 4.39052 9.16667 4.66667 9.16667H11.6667C11.942 9.16667 12.1653 8.86994 12.1667 8.50323C12.1667 8.50216 12.1667 8.50108 12.1667 8.5C12.1667 8.49892 12.1667 8.49784 12.1667 8.49677Z" fill="#E37056"/>
                  </svg>
                  <span class="text-neutral-700"> Tạm dừng </span>
                  </div>`;
				case 'library':
					return `
                  <div class="text-neutral-700">Ngân hàng MT</div>
                  `;
				case 'active':
					return `<div class=" flex items-center gap-2">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				  <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 3.5C8 3.22386 7.77614 3 7.5 3C7.22386 3 7 3.22386 7 3.5V9C7 9.17943 7.09614 9.3451 7.25193 9.43412L10.7519 11.4341C10.9917 11.5711 11.2971 11.4878 11.4341 11.2481C11.5711 11.0083 11.4878 10.7029 11.2481 10.5659L8 8.70984V3.5Z" fill="#6390C3"/>
			      </svg>
                  <span class="text-neutral-700"> Đang học </span>
                  </div>`;
				default:
					return '';
			}
		},

		openSubGoal(goal) {
			if (goal.sub_goal_count > 0) goal.open = !goal.open;
			else return;
		},
	},
});
</script>
