<template>
	<div class="_create-boarding _modal bg-white">
		<div
			class="
				_modal__content
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					alt="Luca Education"
					height="60"
					src="@/assets/images/illustrations/warning.png"
					title="Luca Education"
					width="60"
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Bạn có chắc chắn muốn xóa mục tiêu chung?
			</div>
			<div class="text-center text-neutral-500 mt-2">
				Các mục tiêu cụ thể trong mục tiêu chung sẽ không mất đi, mà sẽ
				được hiển thị như ban đầu
			</div>
			<div class="mt-8">
				<div class="text-neutral-400 uppercase w-1/2 text-xs">
					Tên mục tiêu chung
				</div>
				<div
					class="text-neutral-700 mt-2"
					v-html="goal?.goal_name || goal?.name"
				></div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				type="button"
				@click="$emit('close')"
			>
				Thoát
			</button>
			<button
				class="button-p text-center whitespace-nowrap"
				type="button"
				@click="$emit('submit')"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DeleteConfirm',

	props: {
		goal: {
			type: Object,
			required: true,
		},
	},
};
</script>
