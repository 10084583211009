/* eslint-disable */
import { $http } from '@/plugins/axios';
import { AxiosResponse } from 'axios';

export const staffService = {
	getEmployees: (query: string, oldData?: any): Promise<AxiosResponse> => {
		return $http.get(
			`v1/org_admin/staffs${query}`,
			oldData
				? {
					headers: {
						common: oldData,
					},
				}
				: {}
		);
	},
	getActiveEmployees: (): Promise<AxiosResponse> => {
		return $http.get(
			`v1/org_admin/staffs?state=active`,

		);
	},
	activeStaff: (id: string): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/staffs/${id}/open`);
	},
	closeStaff: (id: string): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/staffs/${id}/close`);
	},
	createStaff: (data: any): Promise<AxiosResponse> => {
		return $http.post(`v1/org_admin/staffs`, data);
	},
	quickCreateStaff: (data: any): Promise<AxiosResponse> => {
		return $http.post(`v1/org_admin/staffs/quick_create`);
	},
	getStaff: (id: string): Promise<AxiosResponse> => {
		return $http.get(`v1/org_admin/staffs/${id}`);
	},
	updateStaff: (id: string, data: any): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/staffs/${id}`, data);
	},
	sendInviteStaff(id: string, data: any): Promise<AxiosResponse> {
		return $http.post(`v1/org_admin/staffs/${id}/invite`, data);
	},
	resendInviteStaff(id: string, email: string): Promise<AxiosResponse> {
		return $http.post(`v1/org_admin/staffs/${id}/reinvite`, {
			email: email,
		});
	},
	validateStaffToken(data: { token: string }): Promise<AxiosResponse> {
		return $http.post(`v1/staff_registrations/validate_token`, data);
	},
	registrations(data: any): Promise<AxiosResponse> {
		return $http.post(`v1/staff_registrations`, data);
	},
	join(data: any): Promise<AxiosResponse> {
		return $http.post(`v1/staff_registrations/join`, data);
	},
	uploadProfilePicture(payload: any) {
		return $http.put(`v1/staff/my_staff/upload_profile_photo`, payload, {
			headers: {
				'content-type': 'multipart/form-data;',
			},
		});
	},
	plans(query: any, oldData?: any) {
		return $http.get(
			`v1/staff/plans`,
			oldData
				? {
					params: query,
					headers: {
						common: oldData,
					},
				}
				: { params: query }
		);
	},
	fetchDashboard: (id: string, params: any): Promise<AxiosResponse> => {
		return $http.get(`v1/org_admin/staffs/${id}/stats_by_month`, {
			params,
		});
	},
	fetchDashboardDayCare: (
		id: string,
		params: any
	): Promise<AxiosResponse> => {
		return $http.get(`v1/org_admin/staffs/${id}/day_care_stats_by_month`, {
			params,
		});
	},
	fetchDashboardMyStaff: (params: any): Promise<AxiosResponse> => {
		return $http.get(`v1/staff/my_staff/stats_by_month`, {
			params,
		});
	},
	getStaffWhenCreateUnSchedule: (params?: any): Promise<AxiosResponse> => {
		return $http.get(
			`v1/org_admin/schedule_sessions/list_staffs_for_create_unscheduled_session`,
			{ params }
		);
	},
	getStaffWhenCreateUnScheduleOverload: (
		params?: any
	): Promise<AxiosResponse> => {
		return $http.post(
			`v1/org_admin/schedule_sessions/list_overlapped_staff_sessions`,
			params
		);
	},

	overloadTimeSlot: (params?: any): Promise<AxiosResponse> => {
		return $http.get(`v1/org_admin/overlaped_time_slots`, { params });
	},

	activeCentre: (id: string): Promise<AxiosResponse> => {
		return $http.put(`v1/org_admin/staffs/${id}/active_center`);
	},

};
