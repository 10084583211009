export const _MODAL_TYPE = {
	INVITE: 'INVITE',
	SEND_EMAIL: 'SEND_EMAIL',
	LEAVE_REPORT: 'LEAVE_REPORT',
	LEAVE_REPORT_LONG: 'LEAVE_REPORT_LONG',
	LEAVE_REPORT_SHORT: 'LEAVE_REPORT_SHORT',
	CHANGE_STATUS: 'CHANGE_STATUS',
	CREATE_ROOM: 'CREATE_ROOM',
	CONFIRM: 'CONFIRM',
	EXIT: 'EXIT',
	DELETE: 'DELETE',
	DELETE_MANY: 'DELETE_MANY',
	EDIT: 'EDIT',
	MAKE_MAIN_STAFF: 'MAKE_MAIN_STAFF',
	CHANGE_STAFF: 'CHANGE_STAFF',
	CREATE: 'CREATE',
	COPY: 'COPY',
	DAYCARECLASS: 'DAYCARECLASS',
	ERROR: 'ERROR',
	UPDATE_NOTE: 'UPDATE_NOTE',
	ADD: 'ADD',
	CHANGE: 'CHANGE',
	ADD_GROUP_CLASS: 'ADD_GROUP_CLASS',
	DELETE_GROUP_CLASS: 'DELETE_GROUP_CLASS',
	WITHDRAW: 'WITHDRAW'
};
