/* eslint-disable */
import { $http } from '@/plugins/axios';
import { AxiosResponse } from 'axios';

export const releaseService = {
    get: (): Promise<AxiosResponse> => {
        return $http.get('v1/release_notes/web_newest');
    },
    getYearSummary: (id: string, dateTime: string): Promise<AxiosResponse> => {
        return $http.get(`v1/account/orgs/${id}/stats?date=${dateTime}`);
    },
}