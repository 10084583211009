
import { Options, setup, Vue } from 'vue-class-component';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { useStore } from 'vuex';
import { Watch } from 'vue-property-decorator';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import ChooseCenter from '@/components/elements/chooseCenter/ChooseCenter.vue';
import { _ROUTER_NAME } from '@/enums';

@Options({
	name: 'SideMenu',
	components: { ChooseCenter, DaModalFrame, DaIcon },
})
export default class SideMenu extends Vue {
	//----------------------- 🤍 Data 🤍 -----------------------//
	isSubOpen = '';
	isSubActive = '';
	language = {
		name: 'VNI',
		flag: 'vni',
	};
	open = false;
	setup = setup(() => {
		const store = useStore();
		return {
			store,
		};
	});

	get menu() {
		return [
			{
				id: 'dashboard',
				name: 'Thống Kê Tổng Quan',
				icon: 'das--grid',
				link: '/dashboard',
				menu: 'dashboard',
				guard: ['leader', 'support', 'org_admin', 'admin'],
				state: ['activated', 'expired'],
				isGuard: true,
				vIf: true,
			},
			{
				id: 'home',
				name: 'Quản lý cơ sở',
				icon: 'das--home',
				menu: 'setup',
				guard: [
					'leader',
					'support',
					'admin',
					'org_admin',
					'accountant',
				],
				state: ['activated', 'community', 'expired'],
				vIf: true,
				subs: [
					{
						id: 'notification',
						name: 'Thông báo',
						icon: '',
						link: '/notifications',
						guard: ['leader', 'support', 'admin', 'org_admin'],
						state: ['activated', 'community', 'expired'],
						isGuard: true,
					},
					// {
					// 	id: 'fee_notices',
					// 	name: 'Thông báo học phí',
					// 	icon: '',
					// 	link: '/fee-notices',
					// 	guard: ['leader', 'support', 'org_admin', 'accountant'],
					// 	state: ['activated', 'expired'],
					// 	isGuard: true,
					// },
					{
						id: 'holiday',
						name: 'Ngày nghỉ',
						icon: '',
						link: '/holiday',
						guard: ['leader', 'support', 'admin', 'org_admin'],
						state: ['activated', 'expired'],
						isGuard: true,
					},
					{
						id: 'birthday',
						name: 'Sinh nhật',
						icon: '',
						link: '/birthdays',
						guard: ['leader', 'support', 'admin', 'org_admin'],
						state: ['activated', 'community', 'expired'],
						isGuard: true,
					},
					{
						id: 'media',
						name: 'Kho video',
						icon: '',
						link: '/media',
						guard: ['leader', 'support', 'admin', 'org_admin'],
						state: ['activated', 'expired'],
						isGuard: true,
					},
					{
						id: 'daycare_class',
						name: 'Lớp bán trú',
						icon: '',
						link: '/daycare-class',
						guard: ['leader', 'support', 'admin', 'org_admin'],
						state: ['activated', 'community', 'expired'],
						isGuard: true,
					},
					{
						id: 'group_classes',
						name: 'Lớp nhóm',
						icon: '',
						link: '/group-classes',
						guard: ['leader', 'support', 'admin', 'org_admin'],
						state: ['activated', 'community', 'expired'],
						isGuard: true,
					},
					{
						id: 'documents',
						name: 'Kho tài liệu',
						icon: '',
						link: '/documents',
						guard: ['leader', 'support', 'admin', 'org_admin'],
						state: ['activated', 'community', 'expired'],
						isGuard: true,
					},
				],
			},
			{
				id: 'record',
				name: 'Quản lý hồ sơ',
				icon: 'das--person',
				menu: 'record',
				isGuard: true,
				guard: [
					'leader',
					'support',
					'admin',
					'org_admin',
					'accountant',
					'teacher',
				],
				state: ['activated', 'community', 'expired'],
				vIf: true,
				subs: [
					{
						id: 'record_employee',
						name: 'Hồ sơ nhân sự',
						icon: '',
						link: '/record/employee',
						isGuard: true,
						guard: [
							'leader',
							'support',
							'admin',
							'org_admin',
							'accountant',
						],
						state: ['activated', 'community', 'expired'],
					},
					{
						id: 'record_student',
						name: 'Hồ sơ học sinh',
						icon: '',
						link: '/record/student',
						isGuard: true,
						guard: [
							'leader',
							'support',
							'admin',
							'org_admin',
							'accountant',
							'teacher',
						],
						state: ['activated', 'community', 'expired'],
					},
					{
						id: 'transferred_data',
						name: 'Hồ sơ chuyển cơ sở',
						icon: '',
						link: '/transferred-data',
						isGuard: this.org_setting.has_org_transfer_history,
						state: ['activated', 'expired'],
						guard: [
							'leader',
							'support',
							'admin',
							'org_admin',
							'accountant',
						],
					},
				],
			},
			{
				id: 'tuition',
				name: 'Quản lý học phí',
				icon: 'das--tuition',
				menu: 'tuition',
				isGuard: true,
				guard: [
					'leader',
					'support',
					'admin',
					'org_admin',
					'accountant',
				],
				state: ['activated', 'expired'],
				vIf: true,
				subs: [
					{
						id: 'tuition_setting',
						name: 'Cài đặt',
						icon: '',
						link: '/tuition/setting',
						isGuard: true,
						guard: [
							'leader',
							'support',
							'admin',
							'org_admin',
							'accountant',
						],
						state: ['activated', 'expired'],
					},
					{
						id: 'fee_notices',
						name: 'Thông báo học phí',
						icon: '',
						link: '/fee-notices',
						guard: ['leader', 'support', 'org_admin', 'accountant'],
						state: ['activated', 'expired'],
						isGuard: true,
					},
				],
			},
			{
				id: 'schedule',
				name: 'Lịch học',
				icon: 'das--calender',
				menu: 'schedule',
				isGuard: true,
				vIf: true,
				guard: ['leader', 'support', 'admin', 'org_admin'],
				state: ['activated', 'expired'],
				subs: [
					{
						id: 'schedule_timeslot',
						name: 'Lịch ngày',
						icon: '',
						link: '/schedule/timeslot',
						isGuard: true,
						guard: ['leader', 'support', 'admin', 'org_admin'],
						state: ['activated', 'expired'],
					},
					{
						id: 'section_management',
						name: 'Quản lý chuyên cần',
						icon: '',
						link: '/session-management',
						isGuard: true,
						guard: ['leader', 'support', 'admin', 'org_admin'],
						state: ['activated', 'expired'],
					},
					{
						id: 'schedule_timetable',
						name: 'Thời khoá biểu',
						icon: '',
						link: '/schedule/timetable',
						isGuard: true,
						guard: ['leader', 'support', 'admin', 'org_admin'],
						state: ['activated', 'expired'],
					},
					{
						id: 'schedule_stats',
						name: 'Thống kê',
						icon: '',
						link: '/schedule/stats',
						isGuard: true,
						guard: ['leader', 'support', 'admin', 'org_admin'],
						state: ['activated', 'expired'],
					},
				],
			},
			{
				id: 'plans',
				name: 'Kế hoạch',
				icon: 'das--goal',
				menu: 'plans',
				isGuard: true,
				vIf: true,
				guard: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
				state: ['activated', 'community', 'expired'],
				subs: [
					{
						id: 'plans_data',
						name: 'Kế hoạch can thiệp',
						icon: '',
						link: '/plans',
						guard: [
							'leader',
							'support',
							'admin',
							'org_admin',
							'teacher',
						],
						state: ['activated', 'community', 'expired'],
						isGuard: true,
						vIf: true,
					},
					{
						id: 'group_class_plans',
						name: 'Kế Hoạch nhóm',
						icon: '',
						link: '/group-class-plans',
						guard: [
							'leader',
							'support',
							'admin',
							'org_admin',
							'teacher',
						],
						state: ['activated', 'community', 'expired'],
						isGuard: true,
						vIf: true,
					},
					{
						id: 'goal_lib',
						name: 'Thư viện nội bộ',
						icon: '',
						link: '/goal-lib',
						guard: [
							'leader',
							'support',
							'admin',
							'org_admin',
							'teacher',
						],
						state: ['activated', 'community', 'expired'],
						isGuard: true,
						vIf: true,
					},
					{
						id: _ROUTER_NAME.GOAL_REFERENCE,
						name: 'Thư viện tham khảo',
						icon: '',
						link: '/goal-reference',
						guard: ['leader', 'support', 'admin', 'org_admin'],
						state: ['activated', 'community', 'expired'],
						isGuard: true,
						vIf: true,
					},
				],
			},
			{
				id: 'report',
				name: 'Báo cáo',
				icon: 'das--report',
				menu: 'report',
				isGuard: true,
				vIf: true,
				guard: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
				state: ['activated', 'expired'],
				subs: [
					{
						id: 'report_day-care',
						name: 'Báo Cáo Bán Trú',
						icon: '',
						link: '/report/day-care',
						isGuard:
							this.org_setting.has_day_care &&
							this.profile.exists_day_care_class,
						guard: [
							'leader',
							'support',
							'admin',
							'org_admin',
							'teacher',
						],
						state: ['activated', 'expired'],
					},
					{
						id: 'report_lesson',
						name: 'Báo Cáo Cá Nhân',
						icon: '',
						link: '/report/lesson',
						isGuard: true,
						guard: [
							'leader',
							'support',
							'admin',
							'org_admin',
							'teacher',
						],
						state: ['activated', 'expired'],
					},
					{
						id: 'report_group',
						name: 'Báo Cáo Nhóm',
						icon: '',
						link: '/report/group',
						isGuard: true,
						guard: [
							'leader',
							'support',
							'admin',
							'org_admin',
							'teacher',
						],
						state: ['activated', 'expired'],
					},
					{
						id: 'report_week',
						name: 'Báo Cáo Tuần',
						icon: '',
						link: '/report/week',
						isGuard: true,
						guard: [
							'leader',
							'support',
							'admin',
							'org_admin',
							'teacher',
						],
						state: ['activated', 'expired'],
					},
					{
						id: 'report_monthly',
						name: 'Báo Cáo Tháng',
						icon: '',
						link: '/report/monthly-report',
						isGuard: true,
						guard: [
							'leader',
							'support',
							'admin',
							'org_admin',
							'teacher',
						],
						state: ['activated', 'expired'],
					},
				],
			},
			{
				id: 'setup_information',
				name: 'Thiết lập cơ sở',
				icon: 'das--setting',
				link: '/setup-center',
				menu: 'setup_information',
				guard: ['leader', 'support', 'admin', 'org_admin'],
				isGuard: true,
				vIf: true,
				state: ['activated', 'community', 'expired'],
			},
		];
	}

	get secondMenu() {
		return [
			{
				id: 'packages',
				name: 'Gói dịch vụ',
				icon: 'das--package',
				link: '/packages',
				menu: 'packages',
				isGuard: true,
				guard: ['leader', 'support', 'accountant'],
				state: ['activated', 'expired'],
			},
			{
				id: 'dashboard_org',
				name: 'Thống kê',
				icon: 'das--statics',
				link: '/dashboard-org',
				menu: 'dashboard_org',
				isGuard: true,
				guard: ['leader', 'support'],
				state: ['activated', 'expired'],
			},
		];
	}

	get profile() {
		return this.setup.store.state.profile;
	}

	get centerState() {
		return this.setup.store.state.profile.center_state;
	}

	get org_setting() {
		return this.setup.store.state.profile.current_org;
	}

	get isShowHTCS() {
		return ['leader', 'support', 'accountant'].includes(
			this.setup.store.state.profile.staff.role
		);
	}

	get menuCode(): any {
		return this.$route.meta.menu;
	}

	get languages() {
		return [
			{
				flag: 'vni',
				name: 'VNI',
			},
			{
				flag: 'eng',
				name: 'ENG',
			},
		];
	}

	mounted() {
		const routeName = this.$route.name;
		if (routeName) {
			this.isSubActive = routeName.toString();
		}
		if (localStorage.getItem('lg') == 'vni') {
			this.language = this.languages[0];
		} else this.language = this.languages[1];
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//

	@Watch('$route')
	onRouteChange() {
		const routeName = this.$route.name;
		if (routeName) {
			this.isSubActive = routeName.toString();
		}
	}

	subOpen(key: string) {
		if (key === this.isSubOpen) {
			this.isSubOpen = '';
		} else {
			this.isSubOpen = key;
		}
	}

	haveSub(array: any) {
		if (!array) {
			return null;
		}
		return array.find((item: { id: string }) => {
			return this.isSubActive.includes(item?.id);
		});
	}

	checkState(array: any) {
		if (!array) {
			return null;
		}
		if (!array.includes(this.centerState)) return true;
		else return false;
	}

	changeLanguage(data: any) {
		this.language = data;
		localStorage.setItem('lg', data.flag);
	}
}
